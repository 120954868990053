import { useState, useEffect } from 'react';
import { useContractRead } from 'wagmi';
import { battlePlanetsContractABI } from './battlePlanetsContract';
import { ethers } from 'ethers';
import { balanceDisplay } from '../Utils';

const useFetchPendingRewards = (account) => {
  const [shouldPoll, setShouldPoll] = useState(Boolean(account));
  const shouldFetch = Boolean(account);

  const { data: pendingRewards, error } = useContractRead({
    address: process.env.REACT_APP_BATTLE_PLANETS_CONTRACT_ADDRESS,
    abi: battlePlanetsContractABI,
    functionName: 'pendingRewards',
    args: shouldFetch ? [account] : undefined,
    watch: shouldPoll, // Control polling with shouldPoll state
    enabled: shouldFetch,
    interval: 3000, // Poll every 3 seconds
  });

  // Stop polling if an error occurs
  useEffect(() => {
    if (error) {
      console.log('could not read rewards');
      setShouldPoll(false);
    }
  }, [error]);

  // Restart polling if account changes and there's no error
  useEffect(() => {
    if (account && !error) {
      setShouldPoll(true);
    }
  }, [account, error]);

  // Format the BigNumber value from wei to ether
  const formattedRewards = pendingRewards
    ? ethers.utils.formatEther(pendingRewards)
    : 0;

  return { pendingRewards: balanceDisplay(formattedRewards) };
};

export default useFetchPendingRewards;
