import React from 'react';
import { Box, Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        background: '#EEEEEE',
        padding: '50px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: '50px',
        }}
      >
        Privacy Policy
      </Typography>
      <Box
        sx={{
          width: { xs: '100%', md: '50%' },
          padding: { xs: '20px', md: '0px' },
        }}
      >
        <Typography variant="h6" gutterBottom>
          Last updated: January 15, 2024
        </Typography>
        <Typography variant="body1" gutterBottom>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: '50px',
          }}
        >
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the Privacy Policy Generator.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: '700',
          }}
          gutterBottom
        >
          Interpretation and definitions
        </Typography>
        <Typography variant="body1" gutterBottom>
          Interpretation
        </Typography>
        <Typography variant="body1" gutterBottom>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Typography>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
