import { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import startFirebase from '../Firebase';

const useSubscribeToPlanets = () => {
  const [planets, setPlanets] = useState([]);

  const { realtimeDB } = startFirebase();

  useEffect(() => {
    const spaceRef = ref(realtimeDB, `/space/galaxy`);

    // Subscribe to the space record and listen for changes
    const unsubscribe = onValue(
      spaceRef,
      (snapshot) => {
        const spaceData = snapshot.val();

        if (spaceData) {
          const planetsArray = Object.values(spaceData);
          setPlanets(planetsArray);
        } else {
          setPlanets([]);
        }
      },
      (error) => {
        console.error(error);
      }
    );

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, [realtimeDB]);

  return planets;
};

export default useSubscribeToPlanets;
