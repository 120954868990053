// library
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onSnapshot, doc } from 'firebase/firestore';
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Local
import startFirebase from '../../Firebase';
import { hideDialog, setStep } from '../Storage/myApesSlice';
import useLeaderboardData from '../../APIs/useLeaderboardData';
import useFetchApe from '../../APIs/useFetchApe';
import Footer from '../Common/footer';
import Layout from '../Common/layout';
import Villain from '../Common/Villain';
import Sape from '../Common/sape';

const StepThree = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [outcome, setOutcome] = useState(null);

  const { firestore } = startFirebase();

  const nft = useSelector((state) => state.myApesVisibility.nft);
  const villain = useSelector((state) => state.myApesVisibility.villain);
  const account = useSelector((state) => state.wallet.account);
  const ape = useFetchApe(account, nft?.id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userBattlesPlayed, userPoints, isLoading } = useLeaderboardData();

  useEffect(() => {
    // Exit if account is not available
    if (!account) return;

    // Data is still loading or not yet initialized
    if (isLoading) {
      return;
    }

    const docRef = doc(firestore, 'leaderboard', account);
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();

          if (data.battlesPlayed > userBattlesPlayed) {
            // A new battle has been played
            const won = data.points > userPoints;
            setOutcome(won ? true : false);
            // You can also update userBattlesPlayed and userPoints here if needed
          }
        } else {
          // Handle case where the document does not exist or user has no battles
          setOutcome(null);
        }
      },
      (error) => {
        // Handle error
        setShow(true);
        setMessage(error);
        setSeverity('error');
      }
    );

    return unsubscribe; // Clean up the listener when the component unmounts
  }, [account, firestore, userBattlesPlayed, userPoints, isLoading]);

  return (
    <Layout title={'The Battle'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflowX: 'hidden',
          overflowY: 'auto', // Enable vertical scrolling
          height: outcome !== null ? `calc(100vh - 128px)` : '100%', // Adjust height to viewport minus bottom Stack height
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            border: outcome === false && '2px solid #00FF00',
          }}
        >
          <Villain
            option={villain}
            showArrow={false}
            showLevel={true}
            reverse={true}
          />
        </Box>
        <Typography
          sx={{
            background: '#111111',
            fontSize: '16px',
            padding: '24px',
            textAlign: 'center',
            width: { xs: '100%', md: '344px' },
            boxSizing: 'border-box',
            color: 'white',
            marginTop: '10px',
            marginBottom: '5px',
          }}
        >
          - VS -
        </Typography>
        <Box
          sx={{
            border: outcome === true && '2px solid #00FF00',
            marginBottom: '10px',
          }}
        >
          <Sape {...ape} selectable={false} />
        </Box>
        <Typography
          sx={{
            background: '#B9BD00',
            padding: '16px',
            fontSize: '14px',
            lineHeight: '16.41px',
            fontWeight: 400,
            width: { xs: '100%', md: '344px' },
            boxSizing: 'border-box',
            textAlign: 'center',
            color: 'black',
            marginTop: '10px',
            marginBottom: '10px',
            display: outcome === null ? 'inline' : 'none',
          }}
        >
          Battle in progress!
        </Typography>
        <Typography
          sx={{
            background: '#00990F',
            border: '2px solid #00FF00',
            padding: '16px',
            fontSize: '14px',
            lineHeight: '16.41px',
            fontWeight: 400,
            width: { xs: '100%', md: '348px' },
            boxSizing: 'border-box',
            textAlign: 'center',
            color: 'white',
            marginBottom: '10px',
            display: outcome === true ? 'inline' : 'none',
          }}
        >
          You won!
        </Typography>
        <Typography
          sx={{
            background: '#810000',
            border: '2px solid #FF0000',
            padding: '16px',
            fontSize: '14px',
            lineHeight: '16.41px',
            fontWeight: 400,
            width: { xs: '100%', md: '348px' },
            boxSizing: 'border-box',
            textAlign: 'center',
            color: 'white',
            marginTop: '10px',
            display: outcome === false ? 'inline' : 'none',
          }}
        >
          You lost!
        </Typography>
        <CircularProgress
          color="secondary"
          size={80}
          sx={{
            display: outcome !== null ? 'none' : 'inline',
          }}
        />
      </Box>
      {outcome !== null && (
        <Footer showCost={false}>
          <Button
            size="large"
            variant="contained"
            color="warning"
            state="Enabled"
            sx={{
              width: { xs: '100%', md: '200px' },
              marginRight: '10px',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              dispatch(hideDialog());
              dispatch(setStep(1));
              navigate('/villains');
            }}
          >
            BATTLE AGAIN
          </Button>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            state="Enabled"
            sx={{
              width: { xs: '100%', md: '200px' },
              textTransform: 'uppercase',
            }}
            onClick={() => {
              dispatch(hideDialog());
              dispatch(setStep(1));
              navigate('/leaderboard');
            }}
          >
            LEADERBOARD
          </Button>
        </Footer>
      )}
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => {
          setShow(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => {
            setShow(false);
          }}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default StepThree;
