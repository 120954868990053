import React, { useRef, useMemo } from 'react';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { useFrame } from '@react-three/fiber';
import { TOTAL_STARS } from '../Constants';

const Stars = () => {
  const meshRef = useRef();
  const stars = useMemo(() => {
    const positions = [];
    for (let i = 0; i < TOTAL_STARS; i++) {
      positions.push(Math.random() * 2000 - 1000); // x
      positions.push(Math.random() * 2000 - 1000); // y
      positions.push(Math.random() * 2000 - 1000); // z
    }
    return positions;
  }, []);

  const geometry = new BufferGeometry();
  geometry.setAttribute('position', new Float32BufferAttribute(stars, 3));

  // Pulsating effect
  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.material.size = 1 + Math.sin(Date.now() * 0.001) * 0.3;
    }
  });

  return (
    <points ref={meshRef} geometry={geometry}>
      <pointsMaterial
        size={1}
        sizeAttenuation
        color="white"
        transparent
        opacity={0.8}
        depthWrite={false}
      />
    </points>
  );
};

export default Stars;
