import { useContractWrite, useContractRead } from "wagmi";
import { parseEther } from "@ethersproject/units";
import { tokenContractABI } from "./tokenContract";

const useApproveTokenContract = (
  account,
  contractAddress,
  setMessage,
  setSeverity,
  setShow
) => {
  // Step 1
  // It reads on the token contract
  // if the user has not or has given permission to the token contract
  // so that it (the contract) may spend money on any activity,
  // on a destination contract i.e. nfts, villain, planet contracts
  const { data: allowance } = useContractRead({
    address: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    abi: tokenContractABI,
    functionName: "allowance",
    args: [account, contractAddress],
    onError() {
      setSeverity("warning");
      setMessage(`Please approve contract first`);
      setShow(true);
    },
  });

  // Step 2
  // It requests the user to approve spending nuts on a target contract
  const { write: approveContract } = useContractWrite({
    address: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    abi: tokenContractABI,
    functionName: "approve",
    args: [contractAddress, parseEther("426942694269")],
    onSuccess() {
      setMessage("Approval successful, please reconnect");
      setSeverity("success");
      setShow(true);
    },
    onError(error) {
      setSeverity("error");
      setMessage(`Approval failed: ${error}`);
      setShow(true);
    },
  });

  return { allowance, approveContract };
};

export default useApproveTokenContract;
