// Libraries
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Stack, Chip, Avatar } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

// Local
import useFetchPlanetsOwned from "../../APIs/useFetchPlanetsOwned";
import useFetchPendingRewards from "../../APIs/useFetchPendingRewards";
import useLeaderboardData from "../../APIs/useLeaderboardData";
import { setMenu } from "../../Wallet/Storage/walletSlice";
import villains from "./villains.png";
import planets from "./planets.png";
import nuticon from "./nuticon.svg";
import planeticon from "./planeticon.svg";

const BattleSpaces = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.wallet.account);
  const { position } = useLeaderboardData();
  const { amountOfPlanetsUserOwns } = useFetchPlanetsOwned(account);
  const { pendingRewards } = useFetchPendingRewards(account);

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "50%" },
        height: "440px",
        background: "#111111",
        boxSizing: "border-box",
        borderRadius: "5px",
        marginLeft: { xs: "0px", sm: "16px" },
        marginTop: { xs: "16px", sm: "0px" },
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          weight: "700",
          lineHeight: "24px",
          color: "white",
          paddingTop: "16px",
          paddingLeft: "16px",
          paddingBottom: "16px",
          borderBottom: "1px solid #424242",
        }}
      >
        BATTLE SPACES
      </Typography>
      <Stack
        onClick={() => {
          if (!account) {
            dispatch(setMenu(true));
          } else {
            navigate("/villains");
          }
        }}
        direction={"column"}
        sx={{
          width: "100%",
          height: "auto",
          background: "#111111",
          padding: "16px",
          boxSizing: "border-box",
          border: "1px solid black",
          backgroundImage: `linear-gradient(to right, #111111, transparent), url(${villains})`,
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPosition: "0 0, top right",
          backgroundSize: "100%, auto",
          borderBottom: "1px solid #424242",
          "&:hover": {
            cursor: "pointer",
            border: "1px solid grey",
          },
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontSize: "16px",
              weight: "700",
              lineHeight: "16px",
              color: "white",
            }}
          >
            FIGHT CRYPTO VILLAINS
          </Typography>
          <EastIcon
            sx={{
              color: "white",
            }}
          />
        </Stack>
        <Typography
          gutterBottom
          sx={{
            fontSize: "12px",
            weight: "700",
            lineHeight: "24px",
            color: "#FFFF00",
          }}
        >
          TRAIN SPACE APES AND WIN WEEKLY PRIZES
        </Typography>
        <Chip
          label={
            !position ? `LEADERBOARD READY` : `#${position} ON LEADERBOARD`
          }
          sx={{
            background: "#3E1046",
            fontSize: "14px",
            color: "#FF9900",
            width: "min-content",
          }}
        />
      </Stack>
      <Stack
        onClick={() => {
          if (!account) {
            dispatch(setMenu(true));
          } else {
            navigate("/planets");
          }
        }}
        direction={"column"}
        sx={{
          width: "100%",
          height: "auto",
          background: "#111111",
          padding: "16px",
          boxSizing: "border-box",
          border: "1px solid black",
          backgroundImage: `linear-gradient(to right, #111111, transparent), url(${planets})`,
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPosition: "0 0, top right",
          backgroundSize: "100%, auto",
          borderBottom: "1px solid #424242",
          "&:hover": {
            cursor: "pointer",
            border: "1px solid grey",
          },
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontSize: "16px",
              weight: "700",
              lineHeight: "16px",
              color: "white",
            }}
          >
            BATTLE FOR PLANETS
          </Typography>
          <EastIcon
            sx={{
              color: "white",
            }}
          />
        </Stack>
        <Typography
          gutterBottom
          sx={{
            fontSize: "12px",
            weight: "700",
            lineHeight: "24px",
            color: "#FFFF00",
          }}
        >
          CONQUER PLANETS AND EARN $NUTS
        </Typography>
        <Stack direction={"row"}>
          <Chip
            label={amountOfPlanetsUserOwns}
            avatar={<Avatar alt="Planet icon" src={planeticon} />}
            sx={{
              background: "#3E1046",
              fontSize: "14px",
              color: "#FF9900",
              width: "min-content",
              marginRight: "5px",
            }}
          />
          <Chip
            label={pendingRewards}
            avatar={<Avatar alt="Planet icon" src={nuticon} />}
            sx={{
              background: "#013654",
              fontSize: "14px",
              color: "#00C2FF",
              width: "min-content",
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default BattleSpaces;
