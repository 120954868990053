// Libraries
import { useEffect, useState } from "react";
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ActionMessage = () => {
  const [callToAction, setCallToAction] = useState(true);

  useEffect(() => {
    setCallToAction(true);
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={callToAction}
      autoHideDuration={3000}
      onClose={() => setCallToAction(false)}
      message="Select planet to conquer"
      sx={{
        marginTop: "64px", // Away from header
        "& .MuiSnackbarContent-root": {
          backgroundColor: "#B9BD00", // Custom background
          border: "1px solid #FFFF00", // Custom border
          color: "black",
        },
      }}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setCallToAction(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default ActionMessage;
