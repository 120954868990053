import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "apes", // apes, summon, fuse or villains
  visible: false,
  step: 1,
  newlyMintedIDs: [],
  fuseLevel: null,
  villain: null,
  nft: null,
};

const myApesSlice = createSlice({
  name: "myApesVisibility",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    showDialog: (state) => {
      state.visible = true;
    },
    hideDialog: (state) => {
      state.visible = false;
    },
    toggleDialog: (state) => {
      state.visible = !state.visible;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setNewlyMintedIDs: (state, action) => {
      state.newlyMintedIDs = action.payload;
    },
    setFuseLevel: (state, action) => {
      state.fuseLevel = action.payload;
    },
    setVillain: (state, action) => {
      state.villain = action.payload;
    },
    setNft: (state, action) => {
      state.nft = action.payload;
    },
    reset: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const {
  showDialog,
  hideDialog,
  toggleDialog,
  setStep,
  setType,
  setNewlyMintedIDs,
  setFuseLevel,
  reset,
  setVillain,
  setNft,
} = myApesSlice.actions;

export default myApesSlice.reducer;
