// Libraries
import { useSelector } from 'react-redux';
import { Typography, Box, Button, Stack, Avatar, Chip } from '@mui/material';
import planeticon from '../../StartPage/components/planeticon.svg';

// Local
import { stringToColor } from '../../Utils';
import useFetchPendingRewards from '../../APIs/useFetchPendingRewards';
import useFetchPlanetsOwned from '../../APIs/useFetchPlanetsOwned';
import token from '../../StartPage/components/token.svg';

const Harvest = () => {
  const account = useSelector((state) => state.wallet.account);
  const { pendingRewards } = useFetchPendingRewards(account);
  const { amountOfPlanetsUserOwns } = useFetchPlanetsOwned(account);

  return (
    <Box
      sx={{
        width: { xs: '95%', md: '600px', lg: '600px' },
        position: 'absolute',
        left: '50%', // Center horizontally
        bottom: 20, // Align to bottom
        transform: 'translateX(-50%)', // Adjust for centering
        height: 'min-content', // Height as small as possible to fit content
        background: 'rgba(17, 17, 17, 0.5)',
        padding: '16px',
        borderRadius: '0px 0px 5px 5px',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        border: '1px solid black',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            weight: '700',
            lineHeight: '16px',
            color: 'white',
          }}
        >
          MY REWARDS
        </Typography>

        <Chip
          label={amountOfPlanetsUserOwns}
          avatar={<Avatar alt="Planet icon" src={planeticon} />}
          sx={{
            background: stringToColor(account),
            fontSize: '14px',
            color: 'black',
            fontWeight: 'bold',
            width: 'min-content',
          }}
        />
      </Stack>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          border: '1px solid #424242',
          background: '#242424',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '3px',
          boxSizing: 'border-box',
          borderRadius: '5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            src={token}
            alt="token"
            style={{ height: '25px', width: 'auto', paddingLeft: '5px' }}
          />
          <Typography
            sx={{
              color: 'white',
              paddingLeft: '5px',
            }}
          >
            {pendingRewards}
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={() => {}}
          color="secondary"
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
          }}
        >
          HARVEST
        </Button>
      </Box>
    </Box>
  );
};

export default Harvest;
