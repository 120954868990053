import { Box, Typography, Button, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { setType, setStep } from "../../GameDialogs/Storage/myApesSlice";
import { useNavigate } from "react-router-dom";

const NoApes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Stack
        sx={{
          textAlign: "center",
          color: "white",
          width: { xs: "100%", md: "380px" },
          background: "#111111",
          padding: "20px",
          borderRadius: "10px",
          boxSizing: "border-box",
          border: "1px solid black",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Summon Apes to get started
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            navigate("/start");
            dispatch(setStep(1));
            dispatch(setType("summon"));
          }}
        >
          Mint
        </Button>
      </Stack>
    </Box>
  );
};

export default NoApes;
