import { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import startFirebase from '../Firebase';

const useFetchApes = (account) => {
  const [apes, setApes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { firestore } = startFirebase();

  useEffect(() => {
    setLoading(true); // Start loading when the effect runs
    setError(null); // Reset error state

    if (!account) {
      // Return early if account not ready
      setApes([]);
      setLoading(false); // Stop loading as there's no account
      return;
    }

    const fetchApes = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(firestore, `/players/${account}/apes`)
        );

        const allApesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setApes(allApesData);
      } catch (err) {
        setError(err); // Set error state if there's an error
      } finally {
        setLoading(false); // Stop loading after fetch is complete
      }
    };

    fetchApes();
  }, [account, firestore]);

  return { apes, isLoading, error }; // Return apes, isLoading, and error states
};

export default useFetchApes;
