import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const getNextResetTime = () => {
  const now = new Date();
  const nextReset = new Date();

  // Set next reset to the next Sunday
  nextReset.setDate(now.getDate() + (7 - now.getDay()));
  // Set time to 10 PM Bern Time (UTC+1 or UTC+2 depending on daylight saving)
  nextReset.setHours(22 - nextReset.getTimezoneOffset() / 60, 0, 0, 0);

  return nextReset;
};

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(getNextResetTime() - new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      let newTimeLeft = getNextResetTime() - currentTime;

      if (newTimeLeft < 0) {
        newTimeLeft = getNextResetTime() - currentTime;
      }

      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return `${days}d ${hours}h ${minutes}min ${seconds}s`;
  };

  return (
    <Typography
      sx={{
        background: '#B9BD00',
        padding: '16px',
        fontSize: '14px',
        lineHeight: '16.41px',
        fontWeight: 400,
        width: { xs: '100%', md: '344px' },
        boxSizing: 'border-box',
        textAlign: 'center',
        color: 'black',
      }}
    >
      The leaderboard resets in {formatTime(timeLeft)}
    </Typography>
  );
};

export default CountdownTimer;
