// Libraries
import React, { useEffect, useState } from 'react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useBalance, useNetwork } from 'wagmi';
import { useDispatch } from 'react-redux';
import { Button, Typography, Snackbar, Alert } from '@mui/material';

// Local
import { setAccount, setBalance, setMenu } from '../Storage/walletSlice';
import { chainAccepted } from '../../Utils';
import WalletConnectLogo from './WalletConnect.svg';

export default function WalletConnect() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { data } = useBalance({
    address,
    token: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    watch: true,
    onError(error) {
      setSeverity('warning');
      setMessage('Could not track balance: ', error);
      setShow(true);
    },
  });

  const { chain } = useNetwork();

  const updateWallet = () => {
    dispatch(setAccount(address));
    dispatch(setBalance(data ? data.formatted : 0));
    dispatch(setMenu(false));
  };

  useEffect(() => {
    if (isConnected) {
      if (chainAccepted(chain.id)) {
        updateWallet();
      } else {
        setSeverity('warning');
        setMessage('Please change to a polygon chain');
        setShow(true);
      }
    }
    // eslint-disable-next-line
  }, [isConnected]);

  return (
    <>
      <Button
        size="large"
        sx={{
          color: 'white',
          height: '240px',
          width: '344px',
          background: '#111111',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          marginBottom: '20px',
          border: '1px solid black',
          '&:hover': {
            background: '#111111',
            cursor: 'pointer',
            border: '1px solid grey',
          },
        }}
        onClick={async () => {
          await open();
          dispatch(setMenu(false));
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
          }}
        >
          CONNECT
        </Typography>
        <img src={WalletConnectLogo} alt="walletconnect logo" />
      </Button>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => setShow(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShow(false)}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
