// Libraries
import React, { useEffect, useState } from 'react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useBalance, useNetwork } from 'wagmi';
import { useDispatch } from 'react-redux';
import { IconButton, Snackbar, Alert } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// Local
import { setAccount, setBalance } from '../Storage/walletSlice';
import { chainAccepted } from '../../Utils';

export default function WalletIcon() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { data } = useBalance({
    address,
    token: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    watch: true,
    onError(error) {
      setSeverity('warning');
      setMessage('Could not track balance: ', error);
      setShow(true);
    },
  });

  const { chain } = useNetwork();

  const updateWallet = () => {
    dispatch(setAccount(address));
    dispatch(setBalance(data ? data.formatted : 0));
  };

  useEffect(() => {
    if (isConnected) {
      if (chainAccepted(chain.id)) {
        updateWallet();
      } else {
        setSeverity('warning');
        setMessage('Please change to a polygon chain');
        setShow(true);
      }
    }
    // eslint-disable-next-line
  }, [isConnected]);

  return (
    <>
      <IconButton
        onClick={async () => {
          await open();
        }}
      >
        <AccountBalanceWalletIcon
          sx={{
            color: isConnected ? 'white' : 'grey',
          }}
        />
      </IconButton>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => setShow(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShow(false)}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
