import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from './APIs/useWallet';

function ProtectedRoute({ children }) {
  const { account } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if (!account) {
      navigate('/');
    }
  }, [account, navigate]);

  // If the user is logged in, render the children components. If not, don't render anything.
  return account ? children : null;
}

export default ProtectedRoute;
