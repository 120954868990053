import { useProgress, Html } from "@react-three/drei";

const LoadingScreen = () => {
  const { progress } = useProgress();

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.85)", // Semi-transparent black
    color: "white",
    fontSize: "20px",
    zIndex: 1000, // Ensure it's above other content
  };

  const textStyle = {
    color: "white",
  };

  return (
    <Html fullscreen>
      <div style={overlayStyle}>
        <p style={textStyle}>Loading... {Math.round(progress)}%</p>
      </div>
    </Html>
  );
};

export default LoadingScreen;
