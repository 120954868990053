import { useContractRead } from "wagmi";
import { battlePlanetsContractABI } from "./battlePlanetsContract";
import { ethers } from "ethers";

const useFetchPlanetsOwned = (account) => {
  const shouldFetch = Boolean(account);

  const { data: amountOfPlanetsUserOwns } = useContractRead({
    address: process.env.REACT_APP_BATTLE_PLANETS_CONTRACT_ADDRESS,
    abi: battlePlanetsContractABI,
    functionName: "amountOfPlanetsUserOwns",
    args: shouldFetch ? [account] : undefined,
    enabled: shouldFetch, // Optionally disable the contract read if account is not available
  });

  // Convert BigNumber to a plain number for display
  const amount = amountOfPlanetsUserOwns
    ? ethers.BigNumber.from(amountOfPlanetsUserOwns).toNumber()
    : 0;

  return { amountOfPlanetsUserOwns: amount };
};

export default useFetchPlanetsOwned;
