// Libraries
import { Clouds, Cloud } from '@react-three/drei';
import { useMemo } from 'react';

const Nebula = () => {
  // Define an array of nebula-like colors

  const nebulaColors = ['#7fff00', '#8a2be2', '#00ced1', '#7b68ee', '#48d1cc'];

  // Use useMemo to create cloud properties only once
  const clouds = useMemo(() => {
    const cloudProps = [];
    for (let i = 0; i < 20; i++) {
      cloudProps.push({
        key: i,
        seed: Math.random() * 100,
        position: [
          Math.random() * 400 - 200,
          Math.random() * 400 - 200,
          Math.random() * 400 - 200,
        ],
        color: nebulaColors[Math.floor(Math.random() * nebulaColors.length)], // Randomly select a color
      });
    }
    return cloudProps;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Clouds>
      {clouds.map(({ key, seed, position, color }) => (
        <Cloud
          key={key}
          seed={seed}
          bounds={400}
          volume={900}
          position={position}
          opacity={0.1}
          color={color} // Apply the randomly selected color
        />
      ))}
    </Clouds>
  );
};

export default Nebula;
