import React, { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader, DoubleSide } from 'three';
import flagTextureImage from '../Assets/flag.webp'; // Path to your flag texture image

const FlagOnPole = ({ position }) => {
  const flagRef = useRef();
  const flagTexture = useLoader(TextureLoader, flagTextureImage);

  // Adjust these values as needed
  const poleHeight = 10;
  const poleRadius = 0.1;
  const flagWidth = 3;
  const flagHeight = 2;

  return (
    <group position={[position[0], position[1] + 5, position[2]]}>
      {/* Flagpole */}
      <mesh position={[0, 0, 0]}>
        <cylinderGeometry args={[poleRadius, poleRadius, poleHeight, 32]} />
        <meshStandardMaterial color="grey" />
      </mesh>

      {/* Flag */}
      <mesh ref={flagRef} position={[1.5, 4, 0]}>
        <planeGeometry args={[flagWidth, flagHeight]} />
        <meshStandardMaterial map={flagTexture} side={DoubleSide} />
      </mesh>
    </group>
  );
};

export default FlagOnPole;
