// Library
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Select, MenuItem } from '@mui/material';

// Local
import useFetchApes from '../../APIs/useFetchApes';
import { setNft, hideDialog } from '../Storage/myApesSlice';
import { setMenu } from '../../Planets/Storage/myPlanetsSlice';
import FullScreenLoader from '../Common/loading';
import Layout from '../Common/layout';
import Sape from '../Common/sape';
import NoApes from '../Common/noApes';

export default function StepOne() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('levels');

  const account = useSelector((state) => state.wallet.account);
  const { apes, isLoading, error } = useFetchApes(account);

  const getFilteredApes = () => {
    switch (filter) {
      case 'id':
        // Sort by ID in descending order
        return [...apes].sort((a, b) => a.id - b.id);
      case 'levels':
        // Sort by Levels in descending order
        return [...apes].sort((a, b) => b.level - a.level);
      case 'attack':
        // Sort by Attack in descending order
        return [...apes].sort((a, b) => b.attack - a.attack);
      case 'defense':
        // Sort by Defence in descending order
        return [...apes].sort((a, b) => b.defense - a.defense);
      default:
        // If filter is default or not set, return the original array
        return apes;
    }
  };

  if (isLoading) return <FullScreenLoader />;
  if (error) return <div>Error loading apes: {error.message}</div>;

  return (
    <Layout title={'My Attacker'}>
      {apes.length > 0 ? (
        <Grid
          container
          spacing={2}
          columns={{ xs: 1, sm: 8, md: 12 }}
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            height: '100%',
            marginTop: '0px',
            paddingBottom: '50px',
            overflowY: 'auto',
            color: 'white',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              background: 'black',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxSizing: 'border-box',
            }}
          >
            <Select
              value={filter}
              displayEmpty
              onChange={(e) => setFilter(e.target.value)}
              sx={{
                borderColor: 'none',
                width: 'auto',
                color: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'none',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:focus-visible': {
                  outline: 'none',
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: 'black',
                    color: 'white',
                  },
                },
              }}
            >
              <MenuItem value={'levels'}>Sort by: Levels</MenuItem>
              <MenuItem value={'id'}>Sort by: ID</MenuItem>
              <MenuItem value={'attack'}>Sort by: Attack</MenuItem>
              <MenuItem value={'defense'}>Sort by: Defence</MenuItem>
            </Select>
          </Box>
          {getFilteredApes().map((option, index) => (
            <Grid
              item
              key={index}
              xs={12} // Full width on extra-small (mobile) screens
              sm={6} // Half width on small to medium screens
              md={3} // One third width on medium and larger screens
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                onClick={() => {
                  dispatch(setNft(option));
                  dispatch(hideDialog());
                  dispatch(setMenu('confirm'));
                }}
                sx={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  width: { xs: '100%', md: '384px' },
                }}
              >
                <Sape {...option} selectable={true} />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoApes />
      )}
    </Layout>
  );
}
