import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: false,
  account: null,
  balance: 0,
  rewards: 0,
  profile: false,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetWalletStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setRewards: (state, action) => {
      state.rewards = action.payload;
    },
  },
});

export const {
  setProfile,
  setAccount,
  setBalance,
  setRewards,
  setMenu,
  resetWalletStore,
} = walletSlice.actions;

export default walletSlice.reducer;
