// library
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { ethers } from 'ethers';
import { useContractWrite } from 'wagmi';
import { Box, Stack, Button, Typography, Snackbar, Alert } from '@mui/material';

// Local
import useApproveTokenContract from '../../APIs/useApproveTokenContract';
import { battleArenaContractABI } from '../../APIs/battleArenaContract';
import { hideDialog, setStep } from '../Storage/myApesSlice';
import Footer from '../Common/footer';
import Layout from '../Common/layout';
import Villain from '../Common/Villain';
import Sape from '../Common/sape';
import useFetchApe from '../../APIs/useFetchApe';

export default function StepTwo() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const nft = useSelector((state) => state.myApesVisibility.nft);
  const villain = useSelector((state) => state.myApesVisibility.villain);
  const account = useSelector((state) => state.wallet.account);

  const ape = useFetchApe(account, nft?.id);

  const { allowance, approveContract } = useApproveTokenContract(
    account,
    process.env.REACT_APP_BATTLE_ARENA_CONTRACT_ADDRESS,
    setMessage,
    setSeverity,
    setShow
  );

  // Attack
  // eslint-disable-next-line
  const { write: attack } = useContractWrite({
    address: process.env.REACT_APP_BATTLE_ARENA_CONTRACT_ADDRESS, // The address of the battle contract
    abi: battleArenaContractABI,
    functionName: 'attack',
    args: [nft.id, villain.id],
    async onSuccess() {
      dispatch(setStep(3));

      // Google Analytics
      ReactGA.event({
        category: 'Villain',
        action: 'Purchased villain attack',
        value: villain.id,
      });
    },
    onError(error) {
      setSeverity('error');
      setMessage(error.message);
      setShow(true);
    },
  });

  return (
    <Layout title={'The battle'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          overflowX: 'hidden',
          overflowY: 'auto', // Enable vertical scrolling
          height: `calc(100vh - 189px)`, // Adjust height to viewport minus bottom Stack height
          width: '100vw',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Stack direction={'column'} spacing={1}>
          <Villain
            option={villain}
            showArrow={false}
            showLevel={true}
            reverse={true}
          />
          <Typography
            sx={{
              background: '#111111',
              fontSize: '16px',
              color: 'white',
              padding: '24px',
              textAlign: 'center',
              width: { xs: '100%', md: '344px' },
              boxSizing: 'border-box',
            }}
          >
            - VS -
          </Typography>
          <Sape {...ape} selectable={false} />
        </Stack>
        <Footer showCost={true} cost={villain.cost}>
          <Button
            size="large"
            variant="contained"
            color="error"
            state="Enabled"
            sx={{
              width: { xs: '100%', md: '200px' },
              marginRight: '10px',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              dispatch(hideDialog());
              dispatch(setStep(1));
            }}
          >
            CANCEL
          </Button>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            state="Enabled"
            sx={{
              width: { xs: '100%', md: '200px' },
              textTransform: 'uppercase',
            }}
            onClick={() => {
              // Check if already approved
              const decimals = 18;
              const total = ethers.utils.formatUnits(allowance, decimals);
              const parsedTotal = parseFloat(total);

              // Unlikely to be approved
              if (parsedTotal === 0.0) {
                setMessage('Please approve and set allowance first');
                setSeverity('warning');
                setShow(true);
                approveContract();
              } else {
                attack();
              }
            }}
          >
            CONTINUE
          </Button>
        </Footer>
        <Snackbar
          open={show}
          autoHideDuration={6000}
          onClose={() => {
            setShow(false);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => {
              setShow(false);
            }}
            severity={severity}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </Layout>
  );
}
