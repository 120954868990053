import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  LinearProgress,
  Box,
  Stack,
  Checkbox,
  Chip,
} from '@mui/material';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import ShieldIcon from '@mui/icons-material/Shield';

function CustomLinearProgress({ value, color, max = 750 }) {
  // Calculate the percentage
  const percentage = (value / max) * 100;

  return (
    <LinearProgress
      variant="determinate"
      color={color}
      value={percentage}
      sx={{ marginBottom: '10px' }}
    />
  );
}

export default function Sape({
  id = 0,
  name,
  level,
  attack = 100,
  defense = 100,
  displayCheck = false,
  selectable = false,
  selected = false,
  insertOrRemoveApe = () => {},
  transparent = false,
}) {
  return (
    <Card
      sx={{
        height: '180px',
        width: { xs: '100%', md: '344px' },
        boxSizing: 'border-box',
        background: transparent ? 'rgba(17, 17, 17, 0.5)' : '#111111',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        border: '1px solid black',
        '&:hover': {
          cursor: selectable && 'pointer',
          border: selectable && '1px solid grey',
        },
      }}
    >
      <Box
        onClick={() => {
          // If we are selecting, then allow
          if (displayCheck) {
            insertOrRemoveApe(id);
          }
        }}
        sx={{
          cursor: displayCheck || selectable ? 'pointer' : 'default',
          background: selected && '#DD00FF',
          '&:hover': {
            background: displayCheck && '#DD00FF',
          },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 164,
            padding: '5px',
            height: '100%',
            boxSizing: 'border-box',
          }}
          image={`/assets/${name}-L${level}.webp`}
          alt={`${name}`}
        />
        <Checkbox
          checked={selected}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{
            display: displayCheck ? 'block' : 'none',
            position: 'relative',
            top: '-50px',
            left: '110px',
            width: '10px',
            '&.Mui-checked': {
              color: '#DD00FF',
            },
            '&:not(.Mui-checked)': {
              color: 'black', // or a different color for unchecked state
            },
          }}
        />
      </Box>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          color: 'white',
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '12px',
            textTransform: 'uppercase',
          }}
        >
          {name} #{id}
        </Typography>
        <Chip
          label={`LEVEL-${level}`}
          sx={{
            cursor: 'inherit',
            textTransform: 'uppercase',
            fontSize: '10px',
            fontWeight: 800,
            lineHeight: '12px',
            padding: '5px',
            background: '#B9BD00',
            border: '1px solid #FFFF00',
          }}
        />
        <Box sx={{ width: '100%' }}>
          <Stack
            direction={'row'}
            sx={{
              width: '100%',
              marginBottom: '5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <SportsMmaIcon
              sx={{
                fontSize: 'small',
                marginRight: '7px',
              }}
            />

            <Typography
              sx={{
                fontSize: '12px',
              }}
            >
              {attack}
            </Typography>
          </Stack>
          <CustomLinearProgress value={attack} color={'secondary'} />
          <Stack
            direction={'row'}
            sx={{
              width: '100%',
              marginBottom: '5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <ShieldIcon
              sx={{
                fontSize: 'small',
                marginRight: '7px',
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
              }}
            >
              {defense}
            </Typography>
          </Stack>
          <CustomLinearProgress value={defense} color={'primary'} />
        </Box>
      </CardContent>
    </Card>
  );
}
