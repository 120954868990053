// Libraries
import React from 'react';
import { Dialog, Slide } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from './Storage/myApesSlice';
import StepOneSummon from './SummonSteps/StepOneSummon';
import StepTwoSummon from './SummonSteps/StepTwoSummon';
import StepThreeSummon from './SummonSteps/StepThreeSummon';
import StepOneFuse from './FuseSteps/StepOneFuse';
import StepTwoFuse from './FuseSteps/StepTwoFuse';
import StepThreeFuse from './FuseSteps/StepThreeFuse';
import StepFourFuse from './FuseSteps/StepFourFuse';
import StepOneVillains from './Villains/StepOneVillains';
import StepTwoVillains from './Villains/StepTwoVillains';
import StepThreeVillains from './Villains/StepThreeVillains';
import StepOnePlanets from './Planets/StepOnePlanets';
import MyApes from './MyApes';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApesDialog() {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.myApesVisibility.visible);
  const type = useSelector((state) => state.myApesVisibility.type);
  const step = useSelector((state) => state.myApesVisibility.step);

  const renderPlanets = () => {
    switch (step) {
      case 1:
        return <StepOnePlanets />;

      default:
        return <div>Invalid step.</div>;
    }
  };

  const renderVillains = () => {
    switch (step) {
      case 1:
        return <StepOneVillains />;
      case 2:
        return <StepTwoVillains />;
      case 3:
        return <StepThreeVillains />;

      default:
        return <div>Invalid step.</div>;
    }
  };

  const renderSummon = () => {
    switch (step) {
      case 1:
        return <StepOneSummon />;
      case 2:
        return <StepTwoSummon />;
      case 3:
        return <StepThreeSummon />;

      default:
        return <div>Invalid step.</div>;
    }
  };

  const renderFuse = () => {
    switch (step) {
      case 1:
        return <StepOneFuse />;
      case 2:
        return <StepTwoFuse />;
      case 3:
        return <StepThreeFuse />;
      case 4:
        return <StepFourFuse />;
      default:
        return <div>Invalid step.</div>;
    }
  };

  const renderApes = () => {
    return <MyApes />;
  };

  return (
    <Dialog
      fullScreen
      open={isVisible}
      onClose={() => {
        dispatch(reset());
      }}
      TransitionComponent={Transition}
    >
      {type === 'summon' && renderSummon()}
      {type === 'fuse' && renderFuse()}
      {type === 'apes' && renderApes()}
      {type === 'villains' && renderVillains()}
      {type === 'planets' && renderPlanets()}
    </Dialog>
  );
}
