import { configureStore } from "@reduxjs/toolkit";
import myApesReducer from "./GameDialogs/Storage/myApesSlice";
import myPlanetsReducer from "./Planets/Storage/myPlanetsSlice";
import walletReducer from "./Wallet/Storage/walletSlice";

export default configureStore({
  reducer: {
    myApesVisibility: myApesReducer,
    planets: myPlanetsReducer,
    wallet: walletReducer,
  },
});
