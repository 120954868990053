import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: "harvest", // harvest, conquer or confirm
  planetId: null,
};

const myPlanetsSlice = createSlice({
  name: "myPlanetsVisibility",
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setPlanetId: (state, action) => {
      state.planetId = action.payload;
    },
    resetPlanetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const { setMenu, setPlanetId, resetPlanetState } =
  myPlanetsSlice.actions;

export default myPlanetsSlice.reducer;
