// library
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Zoom, keyframes } from '@mui/material';

// Local
import { setStep } from '../Storage/myApesSlice';
import sape from './SAPE.png';
import Layout from '../Common/layout';

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

export default function StepTwoSummon() {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setStep(3));
    }, 3000);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout title="SUMMON SAPES" loading={true}>
      <Zoom
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 1500, exit: 750 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            animation: `${float} 2s ease-in-out infinite 1.7s`,
          }}
        >
          <img
            src={sape}
            alt="Sape"
            style={{
              height: '350px',
            }}
          />
        </Box>
      </Zoom>
    </Layout>
  );
}
