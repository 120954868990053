// library
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box } from '@mui/material';

// Local
import { setStep, reset } from '../Storage/myApesSlice';
import useFetchNewlyMintedApes from '../../APIs/useFetchNewlyMintedApes';
import Footer from '../Common/footer';
import Layout from '../Common/layout';
import Sape from '../Common/sape';

export default function StepFour() {
  const dispatch = useDispatch();

  const newlyMintedIDs = useSelector(
    (state) => state.myApesVisibility.newlyMintedIDs
  ); // IDs of the ape that was just fused
  const account = useSelector((state) => state.wallet.account);
  const apes = useFetchNewlyMintedApes(account, newlyMintedIDs);
  const ape = apes[0];

  return (
    <Layout title="SUMMON SAPES">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflowX: 'hidden',
          overflowY: 'auto', // Enable vertical scrolling
          height: `calc(100vh - 128px)`, // Adjust height to viewport minus bottom Stack height
          padding: '20px',
        }}
      >
        <Typography
          gutterBottom
          sx={{
            color: '#EEEEEE',
            border: '1px solid #00FF00',
            background: '#00990F',
            boxSizing: 'border-box',
            padding: '10px',
            textAlign: 'center',
            width: { xs: '100%', md: '332px' },
          }}
        >
          FUSION SUCCESSFUL. <br /> YOU HAVE A NEW SAPE ON LEVEL-
          {ape?.level} now!
        </Typography>
        <Sape {...ape} />

        <Footer showCost={false}>
          <Button
            size="large"
            color="warning"
            variant="contained"
            onClick={() => {
              dispatch(setStep(1));
            }}
            sx={{
              border: '1px solid black',
              color: 'white',
              width: { xs: '100%', md: '200px' },
            }}
          >
            FUSE AGAIN
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => {
              dispatch(reset());
            }}
            sx={{
              border: '1px solid black',
              color: 'white',
              width: { xs: '100%', md: '200px' },
            }}
          >
            HOME
          </Button>
        </Footer>
      </Box>
    </Layout>
  );
}
