import sam from "./Assets/sam.png";
import gary from "./Assets/gary.png";
import kwon from "./Assets/kwon.png";
import caroline from "./Assets/caroline.png";

export const options = [
  {
    id: 0,
    name: "Sam",
    nickname: "Wallets-destroyer",
    winRate: 68,
    cost: 1000,
    image: sam,
    level: 1,
  },
  {
    id: 1,
    name: "Gary",
    nickname: "The Hacker",
    winRate: 57,
    cost: 800,
    image: gary,
    level: 1,
  },
  {
    id: 2,
    name: "Do",
    nickname: "Kaboom",
    winRate: 72,
    cost: 1100,
    image: kwon,
    level: 1,
  },
  {
    id: 3,
    name: "Caroline",
    nickname: "Chaos",
    winRate: 49,
    cost: 700,
    image: caroline,
    level: 1,
  },
];
