// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';
import { polygon, polygonMumbai } from 'wagmi/chains';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

// Local
import './index.css';
import {
  shouldInitializeAnalytics,
  shouldInitializeErrorTracking,
} from './Utils';
import ProtectedRoute from './ProtectedRoute';
import Planets from './Planets';
import Villains from './Villains';
import LandingPage from './LandingPage';
import PrivacyPolicy from './PrivacyPolicy';
import Startpage from './StartPage';
import BaseCamp from './BaseCamp';
import reportWebVitals from './reportWebVitals';
import store from './store';
import Leaderboard from './Leaderboard';

const NotFound = () => {
  return <Navigate to="/" replace />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/privacy',
    element: <PrivacyPolicy />,
  },
  process.env.REACT_APP_LIVE === 'true' && {
    path: '/start',
    element: <Startpage />,
  },
  process.env.REACT_APP_LIVE === 'true' && {
    path: '/basecamp',
    element: (
      <ProtectedRoute>
        <BaseCamp />
      </ProtectedRoute>
    ),
  },
  process.env.REACT_APP_LIVE === 'true' && {
    path: '/planets',
    element: (
      <ProtectedRoute>
        <Planets />
      </ProtectedRoute>
    ),
  },
  process.env.REACT_APP_LIVE === 'true' && {
    path: '/villains',
    element: (
      <ProtectedRoute>
        <Villains />
      </ProtectedRoute>
    ),
  },
  process.env.REACT_APP_LIVE === 'true' && {
    path: '/leaderboard',
    element: (
      <ProtectedRoute>
        <Leaderboard />
      </ProtectedRoute>
    ),
  },
  // Catch-all route
  {
    path: '*',
    element: <NotFound />,
  },
]);

if (process.env.REACT_APP_SENTRY_DSN && shouldInitializeErrorTracking()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  console.warn('Error tracking is disabled.');
}

const chains = [polygon, polygonMumbai];
const projectId = process.env.REACT_APP_WALLET_CONNECT_ID;

const metadata = {
  name: 'Spaceapes Revolution - connection request',
  description: 'Spaceapes revolution requests connecting to your wallet',
  url: 'https://spaceapes.game',
  icons: [
    'https://spaceapes.game/static/media/token.bd008d0e7a52efbc2493a7f5e8bad3d4.svg',
  ],
};

// Google analytics
if (process.env.REACT_APP_GA4_MEASUREMENT_ID && shouldInitializeAnalytics()) {
  ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
} else {
  console.warn('Analytics is disabled.');
}

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    '--w3m-z-index': 9999,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <RouterProvider router={router} />
      </WagmiConfig>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
