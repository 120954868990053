// library
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Local
import { reset } from '../../GameDialogs/Storage/myApesSlice';
import { resetPlanetState } from '../Storage/myPlanetsSlice';
import WalletIcon from '../../Wallet/Components/WalletIcon';

export default function Header() {
  const navigate = useNavigate();
  const account = useSelector((state) => state.wallet.account);
  const planetId = useSelector((state) => state.planets.planetId);
  const dispatch = useDispatch();

  return (
    <AppBar
      sx={{
        position: 'fixed',
        background: 'rgba(17, 17, 17, 0.5)',
        color: 'white',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            navigate('/start');
            dispatch(reset());
            dispatch(resetPlanetState());
          }}
          aria-label="close"
        >
          <ArrowBackIcon />
        </IconButton>
        <Stack
          direction={'column'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div">
            {planetId ? `ATTACK PLANET-${planetId}` : 'BATTLE FOR PLANETS'}
          </Typography>
          {account && (
            <Typography
              variant="caption"
              sx={{
                color: '#9F9F9F',
              }}
            >
              {account.slice(0, 5)}...{account.slice(-5)}
            </Typography>
          )}
        </Stack>
        <WalletIcon />
      </Toolbar>
    </AppBar>
  );
}
