// Libraries
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

// Local
import Scene from "./Components/Scene";
import GameDialogs from "../GameDialogs";
import Header from "./Components/Header";
import Harvest from "./Components/Harvest";
import ActionMessage from "./Components/ActionMessage";
import Conquer from "./Components/Conquer";
import Confirm from "./Components/Confirm";

const Planets = () => {
  const menu = useSelector((state) => state.planets.menu);

  const renderMenu = () => {
    switch (menu) {
      case "harvest":
        return <Harvest />;
      case "conquer":
        return <Conquer />;
      case "confirm":
        return <Confirm />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ height: "100vh", width: "100vw" }}>
        <Header />
        <Scene />
        {renderMenu()}
      </Box>
      <ActionMessage />
      <GameDialogs />
    </>
  );
};

export default Planets;
