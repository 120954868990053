import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { collection, getDocs } from 'firebase/firestore';
import startFirebase from '../Firebase';

const useLeaderboardData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [position, setPosition] = useState(null);
  const [userBattlesPlayed, setUserBattlesPlayed] = useState(0);
  const [userPoints, setUserPoints] = useState(0);
  const account = useSelector((state) => state.wallet.account);
  const { firestore } = startFirebase();

  const calculatePosition = (leaderboard) => {
    leaderboard.forEach((player, index) => {
      if (player.id === account) {
        setPosition(index + 1);
        setUserBattlesPlayed(player.battlesPlayed);
        setUserPoints(player.points);
      }
    });
  };

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const leaderboardCollection = collection(firestore, 'leaderboard');
        const leaderboardSnapshot = await getDocs(leaderboardCollection);

        const leaderboard = leaderboardSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        leaderboard.sort((a, b) => b.points - a.points);

        setLeaderboardData(leaderboard);

        if (account) {
          calculatePosition(leaderboard);
        } else {
          setPosition(null);
          setUserBattlesPlayed(0);
          setUserPoints(0);
        }
      } catch {
        setLeaderboardData([]);
        setPosition(null);
        setUserBattlesPlayed(0);
        setUserPoints(0);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeaderboard();
    // eslint-disable-next-line
  }, [account, firestore]);

  return {
    leaderboardData,
    position,
    userBattlesPlayed,
    userPoints,
    isLoading,
  };
};

export default useLeaderboardData;
