// Libraries
import React, { useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

// Local
import Nebula from '../Planets/Components/Nebula';
import Stars from '../Planets/Components/Stars';

function CameraMovement({ moveFactor = 10 }) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useFrame(({ camera }) => {
    // Desired final position based on mouse movement
    const desiredX = mousePosition.x * moveFactor;
    const desiredY = mousePosition.y * moveFactor;

    // Gradually move the camera towards the desired position
    camera.position.x += (desiredX - camera.position.x) * 0.3;
    camera.position.y += (desiredY - camera.position.y) * 0.3;
    camera.lookAt(0, 0, 0);
  });

  return null;
}

function StarsBackground() {
  return (
    <Canvas
      camera={{ position: [0, 0, 145] }}
      style={{
        position: 'absolute',
        zIndex: -1,
        height: '100vh',
        width: '100vw',
      }}
    >
      <ambientLight intensity={0.5} />
      <directionalLight position={[5, 5, 5]} />
      <Nebula />
      <Stars />
      <CameraMovement />
      <pointLight
        distance={2000}
        decay={2.0}
        position={[-80, 20, -20]}
        intensity={450000}
        color="blue"
      />
      <pointLight
        distance={2000}
        decay={1.9}
        position={[80, -210, 20]}
        intensity={600000}
        color="red"
      />
      <OrbitControls enableZoom={false} />
    </Canvas>
  );
}

export default StarsBackground;
