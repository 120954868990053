// Libraries
import React from "react";
import { useSelector } from "react-redux";
import { Typography, Box, Button } from "@mui/material";

// Local
import token from "./token.svg";
import { balanceDisplay } from "../../Utils";

const WalletStatus = () => {
  const balance = useSelector((state) => state.wallet.balance);

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "60vw" },
        height: "auto",
        background: "#111111",
        padding: "16px",
        borderRadius: "0px 0px 5px 5px",
        flexDirection: "column",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        border: "1px solid black",
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          weight: "700",
          lineHeight: "16px",
          color: "white",
          marginBottom: "16px",
        }}
      >
        WALLET STATUS
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          border: "1px solid #424242",
          background: "#242424",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px",
          boxSizing: "border-box",
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={token}
            alt="token"
            style={{ height: "25px", width: "auto", paddingLeft: "5px" }}
          />
          <Typography
            sx={{
              color: "white",
              paddingLeft: "5px",
            }}
          >
            {balanceDisplay(balance)}
          </Typography>
        </Box>
        <Button
          variant="contained"
          href={process.env.REACT_APP_BUY_NUTS_LINK}
          target="_blank"
          color="secondary"
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
          }}
        >
          GET $NUTS
        </Button>
      </Box>
    </Box>
  );
};

export default WalletStatus;
