// library
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Zoom } from '@mui/material';

// Local
import { setStep } from '../Storage/myApesSlice';
import miniApe from './mini_ape.svg';
import Layout from '../Common/layout';

export default function StepThree() {
  const [zoomIn, setZoomIn] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setZoomIn(false);
    }, 4000);

    setTimeout(() => {
      dispatch(setStep(4));
    }, 5000);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout title="SUMMON SAPES" loading={true}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100% - 65px)',
          width: '100vw',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns
            gridTemplateRows: 'repeat(3, 1fr)', // 3 rows
            gap: '3px', // Gap between boxes
            alignItems: 'center',
            justifyContent: 'center',
            width: '100px', // Width of the outer box
            height: '100px', // Height of the outer box
            boxSizing: 'border-box',
          }}
        >
          {Array.from({ length: 9 }).map((_, index) => (
            <Zoom
              key={index}
              direction="left"
              in={zoomIn}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 2000, exit: 1000 }}
            >
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: `url(${miniApe})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '50px', // Fixed height for each inner box
                  width: '50px', // Fixed width for each inner box
                }}
              />
            </Zoom>
          ))}
        </Box>
      </Box>
    </Layout>
  );
}
