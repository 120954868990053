// Libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
} from '@mui/material';

// Local
import { setMenu } from './Storage/walletSlice';
import Profile from './Components/Profile';
import logo from '../Common/logo.svg';
import WalletConnect from './Components/WalletConnect';
import AddToken from './Components/AddToken';
import background from '../LandingPage/Assets/planetBg.webp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WalletDialog() {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.wallet.menu);

  return (
    <>
      <Dialog
        open={menu}
        fullScreen
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          dispatch(setMenu(false));
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar
          sx={{
            position: 'relative',
            background: '#111111',
            color: 'white',
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: '25px', width: 'auto' }}
            />
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            textAlign: 'center',
            height: '100vh',
            width: '100vw',
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundImage: `url(${background})`,
            backgroundColor: 'black',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '100%, 100%',
            boxSizing: 'border-box',
          }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              textAlign: 'center',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            TO START PLAYING
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              textAlign: 'center',
              color: 'white',
              fontWeight: 'bold',
              marginBottom: '20px',
            }}
          >
            CONNECT WALLET
          </DialogContentText>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <WalletConnect />
            <AddToken />
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Profile />
    </>
  );
}
