// useFetchApes.js
import { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import startFirebase from '../Firebase';

const useFetchNewlyMintedApes = (account, newlyMintedIDs) => {
  const [apes, setApes] = useState([]);

  const { firestore } = startFirebase();

  useEffect(() => {
    if (!account || !newlyMintedIDs || newlyMintedIDs.length === 0) {
      // Return early if account or newlyMintedIDs are not ready
      return;
    }

    const fetchApes = async () => {
      const querySnapshot = await getDocs(
        collection(firestore, `players/${account}/apes`)
      );

      const allApesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const filteredApes = allApesData.filter((ape) =>
        newlyMintedIDs.includes(ape.id)
      );

      setApes(filteredApes);
    };

    fetchApes();
  }, [newlyMintedIDs, account, firestore]);

  return apes;
};

export default useFetchNewlyMintedApes;
