// Libraries
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography, List, ListItem } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

// Local
import { openUrlInNewTab } from "../Utils";
import CountdownTimer from "./Components/CountdownTimer";
import Footer from "../GameDialogs/Common/footer";
import Layout from "../Common/layout";
import useLeaderboardData from "../APIs/useLeaderboardData";

const Leaderboard = () => {
  const navigate = useNavigate();
  const { leaderboardData, position } = useLeaderboardData();
  const account = useSelector((state) => state.wallet.account);

  return (
    <Layout route={"/villains"} showLogo={false} title={"LEADERBOARD"}>
      <Stack
        direction={"column"}
        spacing={1}
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          height: "calc(100vh - 128px)",
          overflowY: "auto",
          width: "100%",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <CountdownTimer />
        <Stack
          direction={"row"}
          sx={{
            width: { xs: "100%", md: "344px" },
            background: "#111111",
            justifyContent: "space-between",
            boxSizing: "border-box",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <AccountBalanceWalletIcon
              sx={{
                color: "#999999",
                marginRight: "10px",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "16px",
                fontWeight: 300,
                color: "#EEEEEE",
              }}
            >
              {account.slice(0, 5)}...{account.slice(-5)}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "16px",
              fontWeight: 700,
              color: "#EEEEEE",
              display: position && "inline",
            }}
          >
            #{position}
          </Typography>
        </Stack>
        <List
          sx={{
            width: { xs: "100%", md: "344px" },
          }}
        >
          {leaderboardData.map((row, index) => (
            <ListItem
              key={row.id}
              sx={{
                background: "#111111",
                padding: "5px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                border: index + 1 === position && "2px solid #8E4101",
              }}
            >
              <Box
                sx={{
                  width: "64px",
                  height: "64px",
                  background:
                    index + 1 === 1
                      ? "#DD00FF"
                      : index + 1 === 2
                      ? "#7D1F8D"
                      : index + 1 === 3
                      ? "#3E1046"
                      : "#242424",
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: index + 1 <= 3 ? "32px" : "20px",
                    lineHeight: "32px",
                    textAlign: "center",
                    color: "#EEEEEE",
                  }}
                >
                  {index + 1}
                </Typography>
              </Box>
              <Stack direction={"column"}>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    color: "#EEEEEE",
                    textTransform: "uppercase",
                  }}
                >
                  {row.id.slice(0, 5) + "..." + row.id.slice(-5)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    lineHeight: "14.4px",
                    color: "#FFF500",
                    textTransform: "uppercase",
                  }}
                >
                  {row.points} POINTS
                </Typography>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Stack>
      <Footer showCost={false}>
        <Button
          size="large"
          variant="contained"
          color="warning"
          state="Enabled"
          sx={{
            width: { xs: "100%", md: "200px" },
            marginRight: "10px",
            textTransform: "uppercase",
          }}
          onClick={() => {
            openUrlInNewTab(process.env.REACT_APP_GITBOOK);
          }}
        >
          HOW IT WORKS
        </Button>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          state="Enabled"
          sx={{
            width: { xs: "100%", md: "200px" },
            textTransform: "uppercase",
          }}
          onClick={() => {
            navigate("/villains");
          }}
        >
          GO TO BATTLE
        </Button>
      </Footer>
    </Layout>
  );
};

export default Leaderboard;
