// Libraries
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";

// Local
import { setMenu } from "../Wallet/Storage/walletSlice";
import Layout from "../Common/layout";
import MyProfile from "./components/MyProfile";
import WalletStatus from "./components/WalletStatus";
import Bazaar from "./components/Bazaar";
import MyArmy from "./components/MyArmy";
import BattleSpaces from "./components/BattleSpaces";
import GameDialogs from "../GameDialogs";

export default function Startpage() {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.wallet.account);

  useEffect(() => {
    if (!account) {
      dispatch(setMenu(true));
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Layout route={"/"} showLogo={true}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          alignItems: "center",
          borderRadius: "5px",
        }}
      >
        <MyProfile />
        <WalletStatus />
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          sx={{
            height: "auto",
            width: { xs: "100%", md: "60vw" },
            alignItems: "flex-start",
            marginTop: "16px",
          }}
        >
          <Stack
            direction={"column"}
            sx={{
              width: { xs: "100%", md: "30vw" },
            }}
          >
            <MyArmy />
            <Bazaar />
            <GameDialogs />
          </Stack>
          <BattleSpaces />
        </Stack>
      </Box>
    </Layout>
  );
}
