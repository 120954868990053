// Libraries
import React, { useState, useRef, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three'; // Import THREE

// Local
import useSubscribeToPlanets from '../../APIs/useSubscribeToPlanets';
import { setMenu, setPlanetId } from '../Storage/myPlanetsSlice';
import { reset } from '../../GameDialogs/Storage/myApesSlice';
import LoadingScreen from './LoadingScreen';
import Spaceship from './Spaceship';
import Stars from './Stars';
import Sun from './Sun';
import Planet from './Planet';
import FlagOnPole from './FlagOnPole';
import AxesHelper from './AxesHelper';
import PlanetRing from './PlanetRing';
import Nebula from './Nebula';

import {
  MAX_DISTANCE,
  MIN_DISTANCE,
  ENABLE_PAN,
  ENABLE_ROTATE,
} from '../Constants';

const SceneContents = () => {
  // Custom hook to subscribe to planets
  const planets = useSubscribeToPlanets();

  const { camera } = useThree();
  const orbitControlsRef = useRef();
  const dispatch = useDispatch();

  const [targetPosition, setTargetPosition] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const lerpFactor = 0.05; // Adjust for speed of movement

  const zoomToPlanet = (position, planetId) => {
    const cameraPositionZ = position[2] + 10; // Distance in front of the planet

    setTargetPosition({
      cameraPosition: [position[0], position[1], cameraPositionZ],
      controlsTarget: position,
    });

    setIsAnimating(true);

    dispatch(setMenu('conquer'));
    dispatch(setPlanetId(planetId));
    dispatch(reset());
  };

  useFrame(() => {
    if (isAnimating) {
      // Directly set the camera's target
      orbitControlsRef.current.target.set(...targetPosition.controlsTarget);

      // Lerp camera position
      camera.position.lerp(
        new THREE.Vector3(...targetPosition.cameraPosition),
        lerpFactor
      );

      // Check if the position is close enough to the target
      if (
        camera.position.distanceTo(
          new THREE.Vector3(...targetPosition.cameraPosition)
        ) < 10
      ) {
        setIsAnimating(false); // Stop the animation
      }
    }
  });

  const spaceshipToPlanet = (siegeEnd, position) => {
    if (Date.now() < siegeEnd) {
      return <Spaceship planetPosition={position} orbitRadius={8} />;
    }

    return null;
  };

  return (
    <>
      <ambientLight intensity={0.9} />
      <spotLight position={[10, 15, 10]} angle={0.3} />
      <pointLight position={[5, 5, 7]} decay={0} intensity={Math.PI} />
      <Sun />
      <Nebula />
      {planets.map((planet) => (
        <group
          key={planet.planetId}
          onClick={() =>
            zoomToPlanet([planet.x, planet.y, planet.z], planet.planetId)
          }
        >
          <Planet
            position={[planet.x, planet.y, planet.z]}
            texture={planet.texture}
            siegeEnd={planet.siegeEnd}
            apr={planet.apr}
            name={planet.texture}
          />
          <FlagOnPole position={[planet.x, planet.y, planet.z]} />
          <PlanetRing
            planetPosition={[planet.x, planet.y, planet.z]} // Replace with the actual position of the planet
            walletAddress={planet.owner}
          />
          {spaceshipToPlanet(planet.siegeEnd, [planet.x, planet.y, planet.z])}
        </group>
      ))}

      <OrbitControls
        ref={orbitControlsRef}
        minDistance={MIN_DISTANCE}
        maxDistance={MAX_DISTANCE}
        enableRotate={ENABLE_ROTATE}
        enablePan={ENABLE_PAN}
      />
      <Stars />
      <AxesHelper />
    </>
  );
};

const Scene = () => {
  return (
    <Canvas camera={{ position: [0, 0, 70] }}>
      <Suspense fallback={<LoadingScreen />}>
        <SceneContents />
      </Suspense>
    </Canvas>
  );
};

export default Scene;
