// Libraries
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import {
  Grid,
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { ethers } from "ethers";
import { useContractWrite } from "wagmi";

// Local
import useApproveTokenContract from "../../APIs/useApproveTokenContract";
import { setStep, hideDialog, setNewlyMintedIDs } from "../Storage/myApesSlice";
import { nftContractABI } from "../../APIs/nftContract";
import Layout from "../Common/layout";
import Footer from "../Common/footer";

const options = [
  {
    title: "1 SAPE",
    cost: 1000,
    info: "1000 $NUTS / SAPE",
    position: "flex-end",
    amount: 1,
  },
  {
    title: "3 SAPES",
    cost: 2700,
    info: "900 $NUTS / SAPE",
    position: "flex-start",
    amount: 3,
  },
  {
    title: "5 SAPES",
    cost: 4000,
    info: "800 $NUTS / SAPE",
    position: "flex-end",
    amount: 5,
  },
  {
    title: "10 SAPES",
    cost: 7000,
    info: "700 $NUTS / SAPE",
    position: "flex-start",
    amount: 10,
  },
];

export default function StepOneSummon() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const account = useSelector((state) => state.wallet.account);

  const { allowance, approveContract } = useApproveTokenContract(
    account,
    process.env.REACT_APP_NFT_CONTRACT_ADDRESS,
    setMessage,
    setSeverity,
    setShow
  );

  async function getMintedNftIds(txHash) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const receipt = await provider.waitForTransaction(txHash);
    const iface = new ethers.utils.Interface(nftContractABI);

    const transferEvents = receipt.logs
      .map((log) => {
        try {
          return iface.parseLog(log);
        } catch (error) {
          // Ignore parsing errors
          return null;
        }
      })
      .filter((log) => log && log.name === "Transfer");

    const mintedNftIds = transferEvents
      .filter((event) => event.args.from === ethers.constants.AddressZero) // Mint events should have a from address of 0x0
      .map((event) => event.args.tokenId.toString()); // Assuming that your Transfer event arg is named 'tokenId'

    return mintedNftIds;
  }

  // Step 3 - Mint the NFTs
  const { write: mint } = useContractWrite({
    address: process.env.REACT_APP_NFT_CONTRACT_ADDRESS, // The address of the nft contract
    abi: nftContractABI,
    functionName: "mint",
    args: [selected ? selected.amount : 1],
    async onSuccess(data) {
      const mintedNftIds = await getMintedNftIds(data.hash);
      setLoading(false);
      dispatch(setNewlyMintedIDs(mintedNftIds));
      dispatch(setStep(2));

      // Google Analytics
      ReactGA.event({
        category: "Mint",
        action: "purchased minting",
        value: selected.amount,
      });
    },
    onError() {
      setLoading(false);
      setSeverity("error");
      setMessage("Summon failed");
      setShow(true);
    },
  });

  return (
    <Layout title="SUMMON SAPES">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowX: "hidden",
          overflowY: "auto", // Enable vertical scrolling
          height: `calc(100vh - 189px)`, // Adjust height to viewport minus bottom Stack height
        }}
      >
        <Box
          sx={{
            height: { xs: "100%", sm: "auto", md: "auto" },
            width: { xs: "100%", md: "400px" }, // Responsive width
            margin: "auto",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              background: "#E3C23F",
              boxSizing: "border-box",
              marginTop: "10px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            Select how many SAPEs you want to summon!
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              height: "inherit",
              overflow: "inherit",
              color: "white",
              marginTop: "0px !important",
            }}
          >
            {options.map((option, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: option.position,
                  },
                  alignItems: "center",
                }}
              >
                <Box
                  onClick={() => {
                    setSelected(option);
                  }}
                  sx={{
                    height: "200px",
                    width: "200px",
                    background: "black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    border:
                      selected === option
                        ? "1px solid #DD00FF"
                        : "1px solid black",
                    "&:hover": {
                      border: "1px solid #DD00FF",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "30px",
                    }}
                  >
                    {option.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "#E3C23F",
                    }}
                  >
                    {option.cost} $NUTS
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#7B7B7B",
                    }}
                  >
                    {option.info}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Footer showCost={true} cost={selected?.cost}>
        <Button
          size="large"
          color="error"
          variant="contained"
          onClick={() => {
            dispatch(hideDialog());
            dispatch(setStep(1));
          }}
          sx={{
            border: "1px solid black",
            color: "white",
            width: { xs: "100%", md: "200px" },
          }}
        >
          CANCEL
        </Button>
        <Button
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => {
            if (!selected) return;
            if (loading) return;

            setLoading(true);

            // Check if already approved
            const decimals = 18;
            const total = ethers.utils.formatUnits(allowance, decimals);

            // Unlikely to be approved
            if (parseFloat(total) === 0.0) {
              setMessage("Please approve and set allowance first");
              setSeverity("warning");
              setShow(true);
              approveContract();
              setLoading(false);
            } else {
              mint();
            }
          }}
          sx={{
            background: !selected && "#2E2E2E",
            border: "1px solid black",
            color: "white",
            width: { xs: "100%", md: "200px" },
          }}
        >
          {loading ? (
            <CircularProgress size={27} color="primary" />
          ) : (
            "CONTINUE"
          )}
        </Button>
      </Footer>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => {
          setShow(false);
          setMessage("");
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShow(false);
            setMessage("");
          }}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Layout>
  );
}
