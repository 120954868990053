// Libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { useContractWrite } from 'wagmi';
import { ethers } from 'ethers';
import {
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';

// Local
import { battlePlanetsContractABI } from '../../APIs/battlePlanetsContract';
import useSubscribeToPlanet from '../../APIs/useSubscribeToPlanet';
import useApproveTokenContract from '../../APIs/useApproveTokenContract';
import useFetchApe from '../../APIs/useFetchApe';
import { reset } from '../../GameDialogs/Storage/myApesSlice';
import { resetPlanetState } from '../Storage/myPlanetsSlice';
import Footer from '../../GameDialogs/Common/footer';
import Sape from '../../GameDialogs/Common/sape';

const Confirm = () => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [outcome, setOutcome] = useState(false);
  const [won, setWon] = useState(null);

  // Used to determine winner
  const [prevSiegeEnd, setPrevSiegeEnd] = useState(null);

  const nft = useSelector((state) => state.myApesVisibility.nft);
  const account = useSelector((state) => state.wallet.account);
  const planetId = useSelector((state) => state.planets.planetId);

  const { planet } = useSubscribeToPlanet(planetId);

  const ape = useFetchApe(account, nft?.id);
  const defenderApe = useFetchApe(planet?.owner, planet?.defId);

  const { allowance, approveContract } = useApproveTokenContract(
    account,
    process.env.REACT_APP_BATTLE_PLANETS_CONTRACT_ADDRESS,
    setMessage,
    setSeverity,
    setShow
  );

  // Subscribe to changes in planet data
  useEffect(() => {
    // Wait for it to be set at success
    if (!prevSiegeEnd) return;

    // If the siege timestamp is greater than old timestamp
    if (planet?.siegeEnd > prevSiegeEnd) {
      // Determine the outcome of the battle
      if (planet?.owner === account) {
        setMessage('You won!');
        setSeverity('success');
        setShow(true);
        setOutcome(true);
        setWon(true);
      } else {
        setMessage('You lost');
        setSeverity('warning');
        setShow(true);
        setOutcome(true);
        setWon(false);
      }

      setLoading(false);
    }

    // eslint-disable-next-line
  }, [planet, account]);

  // Attack
  // eslint-disable-next-line
  const { write: battleStarted } = useContractWrite({
    address: process.env.REACT_APP_BATTLE_PLANETS_CONTRACT_ADDRESS, // The address of the battle contract
    abi: battlePlanetsContractABI,
    functionName: 'battleStarted',
    args: [planetId, nft.id],
    async onSuccess() {
      // Note down the siege end, to compare agaisnt subscription update
      setPrevSiegeEnd(planet?.siegeEnd);

      // Google Analytics
      ReactGA.event({
        category: 'Planets',
        action: 'Purchased planet conquer',
        value: planetId,
      });
    },
    onError(error) {
      setSeverity('error');
      setMessage(error.message);
      setShow(true);
      setLoading(false);
    },
  });

  return (
    <Footer showCost={true} cost={1000} transparent={true}>
      <Box
        sx={{
          position: 'absolute',
          bottom: '140px',
          width: { xs: '100%', md: '344px' },
          paddingLeft: '20px',
          paddingRight: '20px',
          boxSizing: 'border-box',
        }}
      >
        {outcome && (
          <Box
            sx={{
              width: 'inherit',
              height: 'inherit',
              border: outcome
                ? !won
                  ? 'solid 1px green'
                  : 'solid 1px red'
                : 'none',
            }}
          >
            <Sape {...defenderApe} selectable={false} transparent={true} />
          </Box>
        )}
        <Typography
          variant="body1"
          sx={{
            width: { xs: '100%', md: '344px' },
            padding: '20px',
            color: 'white',
            background: 'rgba(17, 17, 17, 0.5)',
            marginTop: '5px',
            marginBottom: '5px',
            textAlign: 'center',
            border: '1px solid black',
            borderRadius: '5px',
            boxSizing: 'border-box',
          }}
        >
          {outcome ? message : 'ATTACKING WITH'}
        </Typography>
        <Box
          sx={{
            width: 'inherit',
            height: 'inherit',
            border: outcome
              ? won
                ? 'solid 1px green'
                : 'solid 1px red'
              : 'none',
          }}
        >
          <Sape {...ape} selectable={false} transparent={true} />
        </Box>
      </Box>
      {!outcome ? (
        <>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(reset());
              dispatch(resetPlanetState());
            }}
            color="error"
            sx={{
              width: { xs: '100%', md: '200px' },
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '24px',
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (loading) return;
              setLoading(true);

              // Check if already approved
              const decimals = 18;
              const total = ethers.utils.formatUnits(allowance, decimals);
              const parsedTotal = parseFloat(total);

              // Unlikely to be approved
              if (parsedTotal === 0.0) {
                setMessage('Please approve and set allowance first');
                setSeverity('warning');
                setShow(true);
                approveContract();
                setLoading(false);
              } else {
                battleStarted();
              }
            }}
            color="secondary"
            sx={{
              width: { xs: '100%', md: '200px' },
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '24px',
            }}
          >
            {loading ? (
              <CircularProgress size={27} color="primary" />
            ) : (
              'CONTINUE'
            )}
          </Button>{' '}
        </>
      ) : (
        <Button
          size="large"
          variant="contained"
          color="secondary"
          state="Enabled"
          sx={{
            width: { xs: '100%', md: '200px' },
            textTransform: 'uppercase',
          }}
          onClick={() => {
            dispatch(reset());
            dispatch(resetPlanetState());
          }}
        >
          BACK TO GAME
        </Button>
      )}
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => {
          setShow(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => {
            setShow(false);
          }}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Footer>
  );
};

export default Confirm;
