import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Layout from "./layout";

const FullScreenLoader = () => (
  <Layout title={"Loading..."}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        background: "transparent",
        top: 0,
        left: 0,
      }}
    >
      <CircularProgress
        sx={{
          color: "white",
        }}
      />
    </Box>
  </Layout>
);

export default FullScreenLoader;
