// Libraries
import { useState, useEffect } from 'react';
import { Box, Stack, Typography, Button, Divider, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EastIcon from '@mui/icons-material/East';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

// Local
import useFetchApes from '../../APIs/useFetchApes';
import { setStep, setType, setFuseLevel } from '../Storage/myApesSlice';
import FullScreenLoader from '../Common/loading';
import Footer from '../Common/footer';
import Layout from '../Common/layout';
import villains from './villains.png';
import miniApe from './mini_ape.svg';
import {
  openUrlInNewTab,
  isFusible,
  totalFusible,
  remainingForFuse,
  MINIMUM_FOR_FUSE,
} from '../../Utils';

const Link = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: { xs: '100%', md: '344px' },
      height: 'auto',
      background: '#111111',
      boxSizing: 'border-box',
      borderRadius: '5px',
    }}
  >
    <Stack
      direction={'row'}
      sx={{
        width: '100%',
      }}
    >
      <Stack
        direction={'column'}
        sx={{
          padding: '16px',
        }}
      >
        <Typography
          gutterBottom
          sx={{
            fontSize: '16px',
            weight: '700',
            lineHeight: '16px',
            color: 'white',
            textTransform: 'uppercase',
          }}
        >
          level up your <br /> sapes with <br />
          fusion
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '14px',
            color: '#FFFF00',
            textTransform: 'uppercase',
          }}
        >
          Once you have {MINIMUM_FOR_FUSE} sapes on the same level you can fuse
          them to get one sape of a higher level
        </Typography>
      </Stack>
      <img
        alt={'villain group'}
        src={villains}
        style={{
          objectFit: 'cover',
          height: 'auto',
          width: '172px',
        }}
      />
    </Stack>
    <Stack
      direction={'row'}
      spacing={2}
      sx={{
        margin: '16px',
      }}
    >
      <Button
        size="large"
        variant="contained"
        color="secondary"
        state="Enabled"
        sx={{
          width: '100%',
          textTransform: 'uppercase',
        }}
        onClick={() => {
          openUrlInNewTab(process.env.REACT_APP_GITBOOK);
        }}
      >
        LEARN MORE
      </Button>
    </Stack>
  </Box>
);

const Option = ({ apes, level }) => {
  const dispatch = useDispatch();
  const [fusible, setFusible] = useState(false);

  useEffect(() => {
    setFusible(isFusible(apes));
  }, [apes]);

  return (
    <Stack
      onClick={() => {
        if (fusible) {
          dispatch(setStep(2));
          dispatch(setFuseLevel(level));
        }
      }}
      direction={'row'}
      sx={{
        width: '100%',
        height: 'auto',
        background: '#111111',
        padding: '5px',
        boxSizing: 'border-box',
        border: '1px solid black',
        '&:hover': {
          cursor: fusible && 'pointer',
          border: fusible && '1px solid grey',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '3px',
          width: '150px',
          height: '100px',
          boxSizing: 'border-box',
          marginRight: '10px',
        }}
      >
        {Array.from({ length: 9 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background:
                apes > 9 && index === 8
                  ? '#013654'
                  : index < apes
                  ? `url(${miniApe})`
                  : '#242424', // Adjusted background logic
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height: '30px', // Fixed height
              width: '30px', // Fixed width
            }}
          >
            {apes > 9 && index === 8 ? <MoreHorizOutlinedIcon /> : null}
          </Box>
        ))}
      </Box>
      <Stack
        direction={'column'}
        sx={{
          width: '100%',
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontSize: '16px',
              weight: '700',
              lineHeight: '16px',
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            fuse level-{level} sapes
          </Typography>
          <EastIcon
            sx={{
              color: 'white',
            }}
          />
        </Stack>
        <Chip
          label={
            fusible ? `${totalFusible(apes)} FUSE POSSIBLE` : 'NO FUSE POSSIBLE'
          }
          size="small"
          sx={{
            background: fusible ? '#3E1046' : '#424242',
            fontSize: '14px',
            color: fusible ? '#FF9900' : '#999999',
            width: 'min-content',
            marginBottom: '5px',
          }}
        />
        {apes < MINIMUM_FOR_FUSE && (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '700', // It should be fontWeight, not weight
              color: '#999999',
            }}
          >
            You need {remainingForFuse(apes)} more sapes on Level-{level} for a
            fuse
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const StepOne = () => {
  const dispatch = useDispatch();

  const [sapes, setSapes] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });

  const account = useSelector((state) => state.wallet.account);
  const { apes, isLoading, error } = useFetchApes(account);

  useEffect(() => {
    // Initialize totals with levels 1, 2, and 3 set to zero
    const totalsPerLevel = apes.reduce(
      (acc, item) => {
        const level = item.level;
        if (acc[level]) {
          acc[level] += 1; // Increment count for this level
        } else {
          acc[level] = 1; // Initialize count for this level if not already present
        }
        return acc;
      },
      { 1: 0, 2: 0, 3: 0, 4: 0 }
    );

    setSapes(totalsPerLevel); // Save the totals per level
  }, [apes]);

  if (isLoading) return <FullScreenLoader />;
  if (error) return <div>Error loading apes: {error.message}</div>;

  return (
    <Layout title="FUSE SAPES">
      <Stack
        direction={{ xs: 'column', sm: 'Row' }}
        spacing={2}
        sx={{
          justifyContent: { xs: 'flex-start', md: 'center' },
          alignItems: 'flex-start',
          height: 'calc(100vh - 128px)',
          overflowY: 'auto',
          width: '100%',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Link />
        <Divider
          sx={{
            width: '20px',
            display: { xs: 'none', sm: 'inline' },
          }}
        />
        <Stack
          direction={'column'}
          spacing={1}
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', md: '344px' },
          }}
        >
          <Option apes={sapes[1]} level={1} />
          <Option apes={sapes[2]} level={2} />
          <Option apes={sapes[3]} level={3} />
          <Option apes={sapes[4]} level={4} />
        </Stack>
      </Stack>
      <Footer showCost={false}>
        <Button
          size="large"
          color="warning"
          variant="contained"
          onClick={() => {
            dispatch(setStep(1));
            dispatch(setType('apes'));
            dispatch(setFuseLevel(null));
          }}
          sx={{
            border: '1px solid black',
            color: 'white',
            width: { xs: '100%', md: '200px' },
          }}
        >
          View all
        </Button>
        <Button
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => {
            dispatch(setStep(1));
            dispatch(setType('summon'));
            dispatch(setFuseLevel(null));
          }}
          sx={{
            border: '1px solid black',
            color: 'white',
            width: { xs: '100%', md: '200px' },
          }}
        >
          Mint
        </Button>
      </Footer>
    </Layout>
  );
};

export default StepOne;
