// Library
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisconnect, useAccount, useBalance } from 'wagmi';

// Local
import { resetPlanetState } from '../Planets/Storage/myPlanetsSlice';
import { reset } from '../GameDialogs/Storage/myApesSlice';
import {
  resetWalletStore,
  setAccount,
  setBalance,
} from '../Wallet/Storage/walletSlice';

export function useWallet() {
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const { address, isConnected, isDisconnected } = useAccount();

  const profile = useSelector((state) => state.wallet.profile);
  const account = useSelector((state) => state.wallet.account);
  const balance = useSelector((state) => state.wallet.balance);

  const { data } = useBalance({
    address,
    token: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    watch: true,
  });

  useEffect(() => {
    if (isConnected) {
      dispatch(setAccount(address));
      dispatch(setBalance(data ? data.formatted : 0));
      dispatch(resetPlanetState());
      dispatch(reset());
    } else {
      disconnect();
      dispatch(resetPlanetState());
      dispatch(reset());
      dispatch(resetWalletStore());
    }

    // eslint-disable-next-line
  }, [address, isConnected, isDisconnected]);

  return { profile, account, balance };
}
