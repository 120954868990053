import React, { useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { TextureLoader } from 'three';
import sunTextureImage from '../Assets/sun.webp';

const Sun = () => {
  const meshRef = useRef();
  const texture = useLoader(TextureLoader, sunTextureImage); // Replace with your lava texture path

  // Animate the texture
  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.0003;
    }
  });

  return (
    <mesh ref={meshRef} position={[0, 0, -300]}>
      <sphereGeometry args={[100, 32, 32]} />
      <meshStandardMaterial
        map={texture}
        emissive="white"
        emissiveIntensity={0.01}
      />
    </mesh>
  );
};

export default Sun;
