// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  Stack,
  ButtonGroup,
  Badge,
} from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';
import startFirebase from '../../Firebase';

// Local
import { showDialog, setType } from '../../GameDialogs/Storage/myApesSlice';
import { setMenu } from '../../Wallet/Storage/walletSlice';
import { totalFusible } from '../../Utils';
import sape from './SAPE.svg';
import useFetchApes from '../../APIs/useFetchApes';

const MyArmy = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.wallet.account);
  const newlyMintedIDs = useSelector(
    (state) => state.myApesVisibility.newlyMintedIDs
  );
  const { apes } = useFetchApes(account);

  const [levelOneApes, setLevelOneApes] = useState([]);
  const [levelTwoApes, setLevelTwoApes] = useState([]);
  const [levelThreeApes, setLevelThreeApes] = useState([]);
  const [levelFourApes, setLevelFourApes] = useState([]);
  const [fuseTotal, setFuseTotal] = useState(0);
  const { firestore } = startFirebase();

  useEffect(() => {
    const levelOne = [];
    const levelTwo = [];
    const levelThree = [];
    const levelFour = [];

    apes.forEach((ape) => {
      switch (ape.level) {
        case 1:
          levelOne.push(ape);
          return;
        case 2:
          levelTwo.push(ape);
          return;
        case 3:
          levelThree.push(ape);
          return;
        case 4:
          levelFour.push(ape);
          return;
        default:
          return;
      }
    });

    setLevelOneApes(levelOne);
    setLevelTwoApes(levelTwo);
    setLevelThreeApes(levelThree);
    setLevelFourApes(levelFour);

    const totalFuses =
      totalFusible(levelOne.length) +
      totalFusible(levelTwo.length) +
      totalFusible(levelThree.length) +
      totalFusible(levelFour.length);

    setFuseTotal(totalFuses);
  }, [account, apes, newlyMintedIDs, firestore]);

  return (
    <Stack
      direction={'column'}
      sx={{
        width: '100%',
        height: '275px',
        background: '#111111',
        boxSizing: 'border-box',
        borderRadius: '5px',
        marginBottom: '16px',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          minHeight: '200px',
        }}
      >
        <Stack
          direction={'column'}
          sx={{
            width: '50%',
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontSize: '24px',
              weight: '700',
              lineHeight: '24px',
              color: 'white',
              paddingTop: '16px',
              paddingLeft: '16px',
            }}
          >
            MY ARMY
          </Typography>
          <Typography
            gutterBottom
            sx={{
              fontSize: '12px',
              weight: '700',
              lineHeight: '24px',
              color: '#FFFF00',
              paddingLeft: '16px',
            }}
          >
            SAPES
          </Typography>
          <Stack
            direction={'column'}
            sx={{
              width: '100%',
              height: '100%',
              padding: '16px',
            }}
          >
            <Stack
              direction={'row'}
              sx={{
                marginBottom: '16px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: '5px',
                }}
              >
                <SquareIcon
                  sx={{
                    color: '#DD00FF',
                    paddingRight: '5px',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: '400',

                    fontSize: { xs: '12px', md: '16px' },
                  }}
                >
                  {levelOneApes.length} Level 1
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <SquareIcon
                  sx={{
                    color: '#FF9900',
                    paddingRight: '5px',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: '400',
                    fontSize: { xs: '12px', md: '16px' },
                  }}
                >
                  {levelTwoApes.length} Level 2
                </Typography>
              </Box>
            </Stack>
            <Stack direction={'row'}>
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: '5px',
                }}
              >
                <SquareIcon
                  sx={{
                    color: '#00C2FF',
                    paddingRight: '5px',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: '400',
                    fontSize: { xs: '12px', md: '16px' },
                  }}
                >
                  {levelThreeApes.length} Level 3
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <SquareIcon
                  sx={{
                    color: '#00FF00',
                    paddingRight: '5px',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: '400',
                    fontSize: { xs: '12px', md: '16px' },
                  }}
                >
                  {levelFourApes.length} Level 4
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={'column'}
          sx={{
            height: '100%',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'flex-end',
            display: 'flex',
            position: 'relative',
          }}
        >
          <img
            src={sape}
            alt="sape"
            style={{
              height: '100px',
              width: 'auto',
              position: 'absolute',
              paddingRight: '25px',
            }}
          />
          <PieChart
            series={[
              {
                paddingAngle: 0,
                cornerRadius: 0,
                innerRadius: 50,
                outerRadius: 60,
                data: [
                  { id: 0, value: levelOneApes.length, color: '#DD00FF' },
                  { id: 1, value: levelTwoApes.length, color: '#FF9900' },
                  { id: 2, value: levelThreeApes.length, color: '#00C2FF' },
                  { id: 3, value: levelFourApes.length, color: '#00FF00' },
                ],
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30 },
              },
            ]}
            sx={{
              border: 'none',
              [`& .${pieArcClasses.faded}`]: {
                fill: 'gray',
              },
            }}
            margin={{ right: 0 }}
            width={150}
            height={150}
            legend={{ hidden: true }}
            tooltip={{ hidden: true }}
          />
        </Stack>
      </Stack>
      <ButtonGroup
        fullWidth={true}
        size="large"
        sx={{
          width: '100%',
          height: '75px',
        }}
      >
        <Button
          onClick={() => {
            if (!account) {
              dispatch(setMenu(true));
            } else {
              dispatch(setType('apes'));
              dispatch(showDialog());
            }
          }}
          key="one"
          sx={{
            borderRadius: '0px',
            borderBottom: 'none',
            borderLeft: 'none',
            borderColor: '#424242 !important',
            fontWeight: 700,
            color: '#DD00FF',
            '&:hover': {
              borderBottom: 'none',
              borderLeft: 'none',
            },
          }}
        >
          VIEW
        </Button>
        <Button
          onClick={() => {
            if (!account) {
              dispatch(setMenu(true));
            } else {
              dispatch(setType('summon'));
              dispatch(showDialog());
            }
          }}
          key="two"
          sx={{
            borderRadius: '0px',
            borderBottom: 'none',
            borderRight: 'none',
            borderColor: '#424242 !important',
            fontWeight: 700,
            color: '#FF9900',
            '&:hover': {
              borderBottom: 'none',
            },
          }}
        >
          MINT
        </Button>
        <Button
          onClick={() => {
            if (!account) {
              dispatch(setMenu(true));
            } else {
              dispatch(setType('fuse'));
              dispatch(showDialog());
            }
          }}
          key="three"
          sx={{
            borderRadius: '0px',
            borderBottom: 'none',
            borderRight: 'none',
            borderColor: '#424242 !important',
            fontWeight: 700,
            color: '#00C2FF',
            '&:hover': {
              borderBottom: 'none',
              borderRight: 'none',
            },
          }}
        >
          <Badge badgeContent={fuseTotal.toString()} color="warning">
            <Typography>FUSE</Typography>
          </Badge>
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default MyArmy;
