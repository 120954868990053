// Libraries
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import {
  Grid,
  Box,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useContractWrite } from 'wagmi';
import { ethers } from 'ethers';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

// Local
import useApproveTokenContract from '../../APIs/useApproveTokenContract';
import useFetchApes from '../../APIs/useFetchApes';
import { nftContractABI } from '../../APIs/nftContract';
import {
  setStep,
  setFuseLevel,
  setNewlyMintedIDs,
} from '../Storage/myApesSlice';
import { MINIMUM_FOR_FUSE } from '../../Utils';
import FullScreenLoader from '../Common/loading';
import Footer from '../Common/footer';
import Layout from '../Common/layout';
import Sape from '../Common/sape';

function ListSapes({ list, selected, insertOrRemoveApe }) {
  return (
    <Box
      direction={{ xs: 'column', sm: 'Row' }}
      spacing={2}
      sx={{
        justifyContent: { xs: 'flex-start', md: 'center' },
        alignItems: 'flex-start',
        height: 'calc(100vh - 189px)',
        overflowY: 'auto',
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        {list.map((option, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={12}
            md={3}
            sx={{
              minWidth: '344px',
              display: 'flex',
              justifyContent: {
                xs: 'center',
                md: 'center',
              },
              alignItems: 'center',
            }}
          >
            <Sape
              {...option}
              displayCheck={true}
              selected={selected.indexOf(option.id) !== -1}
              insertOrRemoveApe={insertOrRemoveApe}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default function StepTwo() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  const fuseLevel = useSelector((state) => state.myApesVisibility.fuseLevel);
  const account = useSelector((state) => state.wallet.account);
  const { apes, isLoading, error } = useFetchApes(account);

  const { allowance, approveContract } = useApproveTokenContract(
    account,
    process.env.REACT_APP_NFT_CONTRACT_ADDRESS,
    setMessage,
    setSeverity,
    setShow
  );

  const insertOrRemoveApe = (id) => {
    const index = selected.indexOf(id);

    if (index === -1) {
      // name not in array, so add it
      setSelected([...selected, id]);
    } else {
      // name in array, so remove it
      setSelected(selected.filter((n) => n !== id));
    }
  };

  async function getFusedNftID(txHash) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const receipt = await provider.waitForTransaction(txHash);
    const iface = new ethers.utils.Interface(nftContractABI);

    const transferEvents = receipt.logs
      .map((log) => {
        try {
          return iface.parseLog(log);
        } catch (error) {
          // Ignore parsing errors
          return null;
        }
      })
      .filter((log) => log && log.name === 'Transfer');

    const mintedNftIds = transferEvents
      .filter((event) => event.args.from === ethers.constants.AddressZero) // Mint events should have a from address of 0x0
      .map((event) => event.args.tokenId.toString()); // Assuming that your Transfer event arg is named 'tokenId'

    return mintedNftIds;
  }

  const { write: fuse } = useContractWrite({
    address: process.env.REACT_APP_NFT_CONTRACT_ADDRESS, // The address of the nft contract
    abi: nftContractABI,
    functionName: 'fuse',
    args: [selected],
    async onSuccess(data) {
      const mintedNftIds = await getFusedNftID(data.hash);
      setLoading(false);
      dispatch(setNewlyMintedIDs(mintedNftIds));
      dispatch(setStep(3));

      // Google Analytics
      ReactGA.event({
        category: 'Fuse',
        action: 'purchased fuse',
      });
    },
    onError() {
      setLoading(false);
      setSeverity('error');
      setMessage('Fusing failed');
      setShow(true);
    },
  });

  const filterByLevel = (level) => {
    return apes.filter((sape) => sape.level === level);
  };

  const selectWeakestSapes = () => {
    // Filter the apes to include only those with level equal to fuseLevel
    const eligibleApes = apes.filter((ape) => ape.level === fuseLevel);

    // Sort the eligible apes array based on the combined criteria of 'attack' and 'defense'
    const sortedApes = [...eligibleApes].sort(
      (a, b) => a.attack + a.defense - (b.attack + b.defense)
    );

    // Only proceed if there are at least 10 eligible apes
    if (sortedApes.length >= 10) {
      // Select the first 10 elements
      const weakestSapesIds = sortedApes.slice(0, 10).map((ape) => ape.id);

      // Check if the current selection matches the 10 weakest Sapes
      const isAlreadySelected =
        weakestSapesIds.every((id) => selected.includes(id)) &&
        selected.length === 10;

      if (isAlreadySelected) {
        // If all 10 weakest Sapes are already selected, clear the selection
        setSelected([]);
      } else {
        // Otherwise, update the selected state to the weakest Sapes
        setSelected(weakestSapesIds);
      }
    } else {
      // If there aren't at least 10, clear the selection
      setSelected([]);
      // Optionally, notify the user that there aren't enough Sapes to select
    }
  };

  if (isLoading) return <FullScreenLoader />;
  if (error) return <div>Error loading apes: {error.message}</div>;

  return (
    <Layout title={`FUSE LEVEL-${fuseLevel} ${selected.length}/10`}>
      <ListSapes
        list={filterByLevel(fuseLevel)}
        selected={selected}
        insertOrRemoveApe={insertOrRemoveApe}
      />
      <Footer showCost={true} cost={1000}>
        <Button
          size="large"
          color="error"
          variant="contained"
          onClick={() => {
            dispatch(setStep(1));
            dispatch(setFuseLevel(null));
          }}
          sx={{
            border: '1px solid black',
            color: 'white',
            width: { xs: '100%', md: '200px' },
          }}
        >
          CANCEL
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={selectWeakestSapes}
          disabled={apes.length < 10}
          sx={{
            border: '1px solid black',
            color: 'white',
            width: 'auto',
          }}
        >
          <AutoAwesomeIcon />
        </Button>
        <Button
          size="large"
          color="warning"
          variant="contained"
          disabled={selected.length !== 10}
          sx={{
            border: '1px solid black',
            color: 'white',
            width: { xs: '100%', md: '200px' },
            background: selected.length !== 10 && '#868585 !important',
          }}
          onClick={() => {
            if (selected.length !== 10) return;
            if (loading) return;
            setLoading(true);

            // Check if already approved
            const decimals = 18;
            const total = ethers.utils.formatUnits(allowance, decimals);
            const parsedTotal = parseFloat(total);

            // Unlikely to be approved
            if (parsedTotal === 0.0) {
              setMessage('Please approve and set allowance first');
              setSeverity('warning');
              setShow(true);
              approveContract();
              setLoading(false);
            } else {
              fuse();
            }
          }}
        >
          {loading ? (
            <CircularProgress size={27} color="primary" />
          ) : (
            `FUSE ${selected.length}/${MINIMUM_FOR_FUSE}`
          )}
        </Button>
      </Footer>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => {
          setShow(false);
          setMessage('');
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => {
            setShow(false);
            setMessage('');
          }}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Layout>
  );
}
