import { Typography, Stack, Chip, CardMedia, Box } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

const Villain = ({ option, showArrow, showLevel, reverse }) => (
  <Stack
    direction={reverse ? "row-reverse" : "row"}
    sx={{
      width: { xs: "100%", md: "344px" },
      height: "auto",
      background: "#111111",
      justifyContent: "space-between",
      boxSizing: "border-box",
    }}
  >
    <Stack
      direction={"column"}
      sx={{
        padding: "16px",
      }}
    >
      <Typography
        gutterBottom
        sx={{
          fontSize: "16px",
          weight: "700",
          lineHeight: "16px",
          color: "white",
          textTransform: "uppercase",
        }}
      >
        fight {option.name} <br /> "{option.nickname}"
      </Typography>
      <Chip
        label={`LEVEL-${option.level}`}
        sx={{
          display: showLevel ? "flex" : "none",
          textTransform: "uppercase",
          fontSize: "10px",
          fontWeight: 800,
          lineHeight: "12px",
          padding: "5px",
          background: "#B9BD00",
          cursor: "pointer",
          border: "1px solid #FFFF00",
          width: "min-content",
          marginBottom: "10px",
        }}
      />
      <Typography
        gutterBottom
        sx={{
          fontSize: "12px",
          lineHeight: "14.4px",
          color: "#FFF500",
          weight: 700,
          textTransform: "uppercase",
        }}
      >
        WINS {option.winRate}% OF FIGHTS
      </Typography>
      <Typography
        gutterBottom
        sx={{
          fontSize: "14px",
          lineHeight: "14px",
          color: "#EEEEEE",
          weight: 700,
          textTransform: "uppercase",
        }}
      >
        {option.cost} $NUTS / FIGHT
      </Typography>
      <EastIcon
        sx={{
          display: showArrow ? "inline" : "none",
          color: "white",
        }}
      />
    </Stack>
    <Box
      sx={{
        width: 164,
        minHeight: 164,
        height: "100%",
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `linear-gradient(${
            reverse ? "to left" : "to right"
          }, #111111 0%, rgba(17, 17, 17, 0) 100%)`,
          zIndex: 1,
        },
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: 164,
          minHeight: 164,
          height: "100%",
          objectFit: "cover",
          boxSizing: "border-box",
          position: "relative", // Added to ensure the image is under the gradient
          zIndex: 0,
        }}
        image={option.image}
        alt={option.name}
      />
    </Box>
  </Stack>
);

export default Villain;
