// library
import { Box } from '@mui/material';

// Local
import Header from './header';
import background from '../../LandingPage/Assets/planetBg.webp';
import loadingBackground from './loading_bg.webp';

const Layout = ({ title, children, loading = false }) => (
  <Box
    sx={{
      backgroundImage: loading
        ? `url(${loadingBackground})`
        : `url(${background})`,
      backgroundColor: 'black',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '100vh', // full screen
      overflow: 'hidden', // Prevents scroll on the main container
    }}
  >
    <Header title={title} />

    <Box
      sx={{
        paddingTop: '64px', // Height of AppBar + some extra space
        height: 'calc(100vh - 64px)', // Adjust this value based on the actual height of the AppBar
        overflowY: 'auto', // Disable at this level
        overflowX: 'hidden',
      }}
    >
      {children}
    </Box>
  </Box>
);

export default Layout;
