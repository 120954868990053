import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';

const style = {
  position: 'absolute',
  bottom: 16,
  right: 16,
  width: 300,
  background: '#111111',
  color: 'white',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  outline: 'none',
};

export default function CookieConsentModal() {
  const [open, setOpen] = useState(false);
  const [analyticsConsent, setAnalyticsConsent] = useState(true);
  const [errorTrackingConsent, setErrorTrackingConsent] = useState(true);

  useEffect(() => {
    const savedAnalyticsConsent =
      localStorage.getItem('analyticsConsent') === 'true';
    const savedErrorTrackingConsent =
      localStorage.getItem('errorTrackingConsent') === 'true';
    const wasPreferenceSaved =
      localStorage.getItem('cookieConsentSaved') === 'true';

    if (wasPreferenceSaved) {
      setAnalyticsConsent(savedAnalyticsConsent);
      setErrorTrackingConsent(savedErrorTrackingConsent);
    } else {
      console.log('reached');
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('analyticsConsent', analyticsConsent);
    localStorage.setItem('errorTrackingConsent', errorTrackingConsent);
    localStorage.setItem('cookieConsentSaved', true);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="cookie-consent-title"
      aria-describedby="cookie-consent-description"
    >
      <Box sx={style}>
        <Typography id="cookie-consent-title" variant="h6" component="h2">
          Cookie consent
        </Typography>
        <Typography id="cookie-consent-description" sx={{ mt: 2, mb: 2 }}>
          We use cookies for our third party analytics tools and error tracking
          to enhance your browsing experience.
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={analyticsConsent}
                onChange={(e) => setAnalyticsConsent(e.target.checked)}
              />
            }
            label="Third-party analytics"
          />
          <FormControlLabel
            control={
              <Switch
                checked={errorTrackingConsent}
                onChange={(e) => setErrorTrackingConsent(e.target.checked)}
              />
            }
            label="Third-party error tracking"
          />
        </FormGroup>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            mt: 2,
          }}
        >
          <Button onClick={handleAccept} color="primary" variant="outlined">
            Save Preferences
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
