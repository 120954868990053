// Libraries
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

// Local
import useFetchApes from "../../APIs/useFetchApes";
import FullScreenLoader from "../Common/loading";
import Layout from "../Common/layout";
import Sape from "../Common/sape";
import NoApes from "../Common/noApes";

export default function MyApes() {
  const account = useSelector((state) => state.wallet.account);
  const { apes, isLoading, error } = useFetchApes(account);

  if (isLoading) return <FullScreenLoader />;
  if (error) return <div>Error loading apes: {error.message}</div>;

  return (
    <Layout title="MY SPACE APES">
      {apes.length > 0 ? (
        <Grid
          container
          spacing={2}
          columns={{ xs: 1, sm: 8, md: 12 }}
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            height: "inherit",
            overflow: "inherit",
            color: "white",
            paddingLeft: "20px",
            paddingRight: "20px",
            marginTop: "0px !important",
          }}
        >
          {apes.map((option, index) => (
            <Grid
              item
              key={index}
              xs={12} // Full width on extra-small (mobile) screens
              sm={6} // Half width on small to medium screens
              md={3} // One third width on medium and larger screens
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: "100%", md: "384px" },
              }}
            >
              <Sape {...option} selectable={false} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoApes />
      )}
    </Layout>
  );
}
