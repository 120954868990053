// Libraries
import { useDispatch } from 'react-redux';
import { useDisconnect } from 'wagmi';
import { Box, Button, Typography, Modal, Stack } from '@mui/material';

// Local
import { useWallet } from '../../APIs/useWallet';
import { resetPlanetState } from '../../Planets/Storage/myPlanetsSlice';
import { reset } from '../../GameDialogs/Storage/myApesSlice';
import { setProfile, resetWalletStore } from '../Storage/walletSlice';
import { balanceDisplay } from '../../Utils';
import TokenLogo from './Token.svg';

export default function Profile() {
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const { profile, account, balance } = useWallet();

  return (
    <>
      <Modal
        open={profile}
        onClose={() => {
          dispatch(setProfile(false));
        }}
        aria-labelledby="profile"
        aria-describedby="user's profile"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', md: 'auto' },
            boxSizing: 'border-box',
            background: '#111111',
            border: '2px solid #000',
            outline: 'none',
            boxShadow: 24,
            color: 'white',
            p: 2,
          }}
        >
          <Typography id="profile" variant="h6" component="h2" gutterBottom>
            MY PROFILE
          </Typography>
          <Stack
            direction={'column'}
            sx={{
              marginBottom: '20px',
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#E3C23F' }}
              gutterBottom
            >
              WALLET
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                width: 'inherit',
                overflowWrap: 'break-word',
                color: 'white',
              }}
            >
              {account}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#E3C23F' }}
              gutterBottom
            >
              $NUTS
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'white',
              }}
              gutterBottom
            >
              <img
                src={TokenLogo}
                style={{
                  width: '24x',
                  height: '24px',
                  marginRight: '5px',
                }}
                alt="nuts token logo"
              />
              {balanceDisplay(balance)}
            </Typography>
          </Stack>
          <Button
            size="large"
            color="warning"
            variant="contained"
            sx={{
              color: 'white',
              width: '100%',
            }}
            onClick={() => {
              disconnect();
              dispatch(resetPlanetState());
              dispatch(reset());
              dispatch(resetWalletStore());
            }}
          >
            DISCONNECT
          </Button>
        </Box>
      </Modal>
      {/* <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => setShow(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShow(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar> */}
    </>
  );
}
