// Libraries
import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useTexture, Text, Billboard } from '@react-three/drei';
import * as THREE from 'three';

// Local
import marsTextureImage from '../Assets/mars.webp';
import earthTextureImage from '../Assets/earth.webp';
import jupiterTextureImage from '../Assets/jupiter.webp';
import venusTextureImage from '../Assets/venus.webp';
import uranusTextureImage from '../Assets/uranus.webp';
import neptuneTextureImage from '../Assets/neptune.webp';
import mercuryTextureImage from '../Assets/mercury.webp';
import saturnTextureImage from '../Assets/saturn.webp';
import alpineTextureImage from '../Assets/alpine.webp';
import savannahTextureImage from '../Assets/savannah.webp';
import swampTextureImage from '../Assets/swamp.webp';
import tropicalTextureImage from '../Assets/tropical.webp';
import moonTextureImage from '../Assets/moon.webp';
import Explosion from './Explosion';

// These are equirectangular planet textures
const getTexture = (texture) => {
  switch (texture) {
    case 'earth':
      return earthTextureImage;
    case 'mars':
      return marsTextureImage;
    case 'jupiter':
      return jupiterTextureImage;
    case 'venus':
      return venusTextureImage;
    case 'uranus':
      return uranusTextureImage;
    case 'neptune':
      return neptuneTextureImage;
    case 'mercury':
      return mercuryTextureImage;
    case 'saturn':
      return saturnTextureImage;
    case 'alpine':
      return alpineTextureImage;
    case 'savannah':
      return savannahTextureImage;
    case 'swamp':
      return swampTextureImage;
    case 'tropical':
      return tropicalTextureImage;
    case 'moon':
      return moonTextureImage;
    default:
      return earthTextureImage; // Default case if texture doesn't match any planet
  }
};

// Generate random points on the surface of a sphere (the planet)
// Generate a single random point on the surface of a sphere (the planet)
const generateRandomPointOnSphere = (radius) => {
  const phi = Math.acos(2 * Math.random() - 1);
  const theta = Math.random() * 2 * Math.PI;
  return new THREE.Vector3(
    radius * Math.sin(phi) * Math.cos(theta),
    radius * Math.sin(phi) * Math.sin(theta),
    radius * Math.cos(phi)
  );
};

const Planet = ({ position, texture, siegeEnd, name, apr }) => {
  const [explosions, setExplosions] = useState([]);

  const meshRef = useRef();
  const moonRef = useRef();

  const map = useTexture(getTexture(texture));
  const moonMap = useTexture(getTexture('moon')); // Assuming you have a moon texture

  const moonOrbitRadius = 15; // Adjust as needed
  const moonSpeed = 0.09; // Adjust as needed

  const handlePointerOver = () => {
    document.body.style.cursor = 'pointer';
  };

  const handlePointerOut = () => {
    document.body.style.cursor = 'default';
  };

  useFrame(({ clock }) => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.001;
    }

    if (moonRef.current) {
      const elapsed = clock.getElapsedTime();
      // Calculate moon's position in orbit
      moonRef.current.position.x =
        position[0] + moonOrbitRadius * Math.sin(elapsed * moonSpeed);
      moonRef.current.position.z =
        position[2] + moonOrbitRadius * Math.cos(elapsed * moonSpeed);
    }
  });

  const renderMoon = () => {
    return (
      texture === 'earth' && (
        <mesh
          ref={moonRef}
          position={[position[0] + moonOrbitRadius, position[1], position[2]]}
        >
          <sphereGeometry args={[1, 32, 32]} />
          <meshStandardMaterial map={moonMap} />
        </mesh>
      )
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() < siegeEnd) {
        // Generate a single random point for a new explosion
        const newExplosionPoint = generateRandomPointOnSphere(5.1);
        setExplosions((currentExplosions) => [
          ...currentExplosions,
          newExplosionPoint,
        ]);
      } else {
        clearInterval(interval);
      }
    }, 4000); // New explosion every second, adjust as needed

    return () => clearInterval(interval);
  }, [siegeEnd]);

  const handleExplosionComplete = (explosionIndex) => {
    setExplosions((currentExplosions) =>
      currentExplosions.filter((_, index) => index !== explosionIndex)
    );
  };

  // const textPosition = [position[0], position[1] + 6, position[2]];

  return (
    <>
      <mesh
        ref={meshRef}
        position={position}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <sphereGeometry args={[5, 32, 32]} />
        <meshStandardMaterial map={map} />

        {explosions.map((point, index) => (
          <Explosion
            key={index}
            position={point} // Pass the Vector3 directly
            onComplete={() => handleExplosionComplete(index)}
          />
        ))}
      </mesh>
      <Billboard
        follow={true}
        position={[position[0], position[1] + 12, position[2]]}
      >
        <Text
          fontSize={0.5} // Adjust font size as needed
          color="white" // Adjust color as needed
          anchorX="center" // Ensures the text is centered
          anchorY="middle"
        >
          {`${name.toUpperCase()} - APR: ${apr}%`}
        </Text>
      </Billboard>
      {renderMoon()}
    </>
  );
};

export default Planet;
