// Lbraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  Stack,
  IconButton,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// Local
import WalletDialog from '../Wallet';
import logo from './logo.svg';
import WalletIcon from '../Wallet/Components/WalletIcon';
import StarsBackground from './StarsBackground';

export default function Layout({ title, route, showLogo = false, children }) {
  const navigate = useNavigate();

  return (
    <Box
      direction="column"
      sx={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          background: '#111111',
          color: 'white',
          width: '100%',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              navigate(route);
            }}
            aria-label="close"
          >
            <ChevronLeftIcon />
          </IconButton>
          {showLogo ? (
            <Stack
              direction="column"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{ height: '25px', width: 'auto' }}
              />
            </Stack>
          ) : (
            <Typography
              component="div"
              sx={{
                fontSize: '16px',
                weight: 800,
                lineHeight: '18.75px',
                position: 'relative',
                left: '10px',
                color: '#FFFFFF',
              }}
            >
              {title}
            </Typography>
          )}

          <WalletIcon />
        </Toolbar>
        <WalletDialog />
      </AppBar>
      <Box
        sx={{
          height: 'calc(100vh - 64px)',
          overflow: 'auto',
        }}
      >
        <StarsBackground />
        {children}
      </Box>
    </Box>
  );
}
