import React, { useRef, useMemo } from 'react';
import { stringToColor } from '../../Utils';

const PlanetRing = ({ planetPosition, walletAddress }) => {
  const ringRef = useRef();

  const color = useMemo(() => {
    // Return a default color or calculate color based on walletAddress
    return walletAddress ? stringToColor(walletAddress) : null;
  }, [walletAddress]);

  // Calculate the average radius and thickness
  const averageRadius = (19 + 20) / 2;
  const thickness = (20 - 19) / 2;

  // If no walletAddress is provided, don't render the ring
  if (!color) {
    return null;
  }

  return (
    <mesh
      ref={ringRef}
      position={planetPosition}
      rotation={[Math.PI / 1.7, 0, 0]} // Tilt the ring by π/6 radians (approximately 30 degrees)
    >
      <torusGeometry args={[averageRadius, thickness, 4, 100]} />
      <meshStandardMaterial
        color={color}
        transparent={true}
        opacity={0.5} // Set opacity to 50%
      />
    </mesh>
  );
};

export default PlanetRing;
