import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import startFirebase from '../Firebase';

const useFetchApe = (account, id) => {
  const [ape, setApe] = useState({});

  const { firestore } = startFirebase();

  useEffect(() => {
    if (!account || !id) {
      // Return early if account or id are not ready
      return;
    }

    const fetchApe = async () => {
      const docRef = doc(firestore, `/players/${account}/apes/${id}`);

      const docSnap = await getDoc(docRef);

      setApe({
        id: docSnap.id,
        ...docSnap.data(),
      });
    };

    fetchApe();
  }, [account, id, firestore]);

  return ape;
};

export default useFetchApe;
