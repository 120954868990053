// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Slide,
  Fade,
  styled,
  keyframes,
  Tooltip,
} from '@mui/material';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import CampaignIcon from '@mui/icons-material/Campaign';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { IoLogoDiscord } from 'react-icons/io5';

// Local
import { openUrlInNewTab } from '../Utils';
import background from './Assets/planetBg.webp';
import SarLogo from './Assets/SarLogoSape.svg';

import Able from './Assets/Able.svg';
import Sam from './Assets/SamFlipped.svg';
import CookieConsentModal from '../Common/Cookies';

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const pulsateBorder = keyframes`
  0% {
    box-shadow: 0 0 0 0 blue;
  }
  50% {
    box-shadow: 0 0 10px 10px blue, 0 0 15px 15px lightblue;
  }
  100% {
    box-shadow: 0 0 0 0 blue;
  }
`;

const CustomButton = styled(Button)(() => ({
  background: 'rgb(0,0,0,0.8)', // Black background for the black hole effect
  height: '150px',
  width: '150px',
  color: 'white',
  border: '5px solid darkblue', // Dark blue border
  borderRadius: '50%',
  padding: '10px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: process.env.REACT_APP_LIVE === 'true' ? 'pointer' : 'not-allowed',
  animation: `${pulsateBorder} 2s linear infinite`, // Updated animations
  boxShadow:
    '0 0 20px 5px darkblue, 0 0 30px 10px lightblue, 0 0 40px 15px blue', // Blue hues for the glowing effect
  transition: 'all 0.3s',
  fontSize: '18px !important',
  fontWeight: 'bold',
  '@media (max-width:599px)': {
    height: '60px',
    width: '60px',
    fontSize: '10px !important',
  },
  '@media (min-width:600px) and (max-width:959px)': {
    height: '100px',
    width: '100px',
    fontSize: '12px !important',
  },
  '@media (min-width:960px)': {
    height: '150px',
    width: '150px',
  },
}));

export default function LandingPage() {
  const [checked, setChecked] = useState(false); // Used for animating sapes
  const [fading, setFading] = useState(true); // Used for animating sapes
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setChecked(true);
    }, 400);
  }, []);

  return (
    <Fade in={fading} timeout={1500}>
      <Stack
        direction="column"
        sx={{
          justifyContent: 'center',
          alignItems: { xs: 'center', md: 'flex-start' },
          height: '100vh',
          width: '100vw',
          padding: { xs: '0px', md: '0' },
          boxSizing: 'border-box',
          overflowY: 'hidden !important',
          backgroundImage: `url(${background})`,
          backgroundColor: 'black',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <CookieConsentModal />
        <AppBar
          sx={{
            backgroundColor: 'black',
            borderTop: '2px solid #999',
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column-reverse',
                sm: 'column-reverse',
                md: 'row',
              },
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              backgroundColor: '#000000b0',
              height: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <Stack>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    marginTop: '10px',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                >
                  players battle for land, power, and resources with their Space
                  apes to conquer the Simistar Galaxy.
                </Typography>
                <Stack direction={'row'} spacing={2}>
                  <Tooltip title="Twitter / X.com">
                    <Box
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'grey',
                        },
                      }}
                    >
                      <FaSquareXTwitter
                        style={{
                          height: '25px',
                        }}
                        onClick={() => openUrlInNewTab(process.env.REACT_APP_X)}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Telegram">
                    <TelegramIcon
                      onClick={() =>
                        openUrlInNewTab(process.env.REACT_APP_TELEGRAM)
                      }
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'grey',
                        },
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Telegram Announcements">
                    <CampaignIcon
                      onClick={() =>
                        openUrlInNewTab(
                          process.env.REACT_APP_TELEGRAM_ANNOUNCEMENTS
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'grey',
                        },
                      }}
                    />
                  </Tooltip>
                  {/* <Tooltip title="Youtube">
                    <YouTubeIcon
                      onClick={() =>
                        openUrlInNewTab(process.env.REACT_APP_YOUTUBE)
                      }
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'grey',
                        },
                      }}
                    />
                  </Tooltip>
                  */}
                  <Tooltip title="Discord">
                    <Box
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          color: 'grey',
                        },
                      }}
                    >
                      <IoLogoDiscord
                        style={{
                          height: '25px',
                        }}
                        onClick={() =>
                          openUrlInNewTab(process.env.REACT_APP_DISCORD)
                        }
                      />
                    </Box>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                width: { xs: '100%', md: 'auto' },
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                padding: 0,
                margin: 0,
                position: 'relative',
                top: '-2px',
              }}
            >
              {/* <Button
                disableRipple
                size="small"
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  marginTop: 0,
                  width: { xs: '100%', md: 'auto' },
                  marginBottom: '20px',
                  marginRight: '20px',
                }}
                onClick={() => openUrlInNewTab(process.env.REACT_APP_GITBOOK)}
              >
                <Typography
                  variant="span"
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    fontWeight: 'bold',
                    position: 'relative',
                    padding: '12px 24px 12px 24px',
                    display: 'inline-block',
                    border: '#999 solid 2px',

                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    textTransform: 'uppercase',
                    color: '#fff',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-12px',
                      left: '-11px',
                      width: '20px',
                      height: '20px',
                      transform: 'rotate(45deg)',
                      borderTop: '#999 solid 2px',
                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    },
                  }}
                >
                  Whitepaper
                </Typography>
              </Button> */}
              <Button
                disableRipple
                size="small"
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  width: { xs: '100%', md: 'auto' },
                  marginBottom: '20px',
                }}
                onClick={() => openUrlInNewTab(process.env.REACT_APP_PITCHDECK)}
              >
                <Typography
                  variant="span"
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    fontWeight: 'bold',
                    position: 'relative',
                    padding: '12px 24px 12px 24px',
                    display: 'inline-block',
                    border: '#999 solid 2px',
                    marginLeft: '0px',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    textTransform: 'uppercase',
                    color: '#fff',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-12px',
                      left: '-11px',
                      width: '20px',
                      height: '20px',
                      transform: 'rotate(45deg)',
                      borderTop: '#999 solid 2px',
                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    },
                  }}
                >
                  Pitchdeck
                </Typography>
              </Button>
              {/* <Button
                disableRipple
                size="small"
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  width: { xs: '100%', md: 'auto' },
                  marginBottom: '20px',
                }}
                onClick={() =>
                  openUrlInNewTab(process.env.REACT_APP_GIVEAWAY_LINK)
                }
              >
                <Typography
                  variant="span"
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    fontWeight: 'bold',
                    position: 'relative',
                    padding: '12px 24px 12px 24px',
                    display: process.env.REACT_APP_GIVEAWAY
                      ? 'inline-block'
                      : 'none',
                    border: '#999 solid 2px',
                    marginLeft: { xs: 0, md: '20px' },
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    textTransform: 'uppercase',
                    color: '#fff',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-12px',
                      left: '-11px',
                      width: '20px',
                      height: '20px',
                      transform: 'rotate(45deg)',
                      borderTop: '#999 solid 2px',
                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                    },
                  }}
                >
                  GIVEAWAY
                </Typography>
              </Button> */}
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            margin: 'auto',
            textAlign: 'center',
            height: '100%',
            width: '80%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              animation: ' fadeIn 2s, floatIn 1s ease-in-out',
              padding: '50px',
              boxSizing: 'border-box',
            }}
          >
            <img
              src={SarLogo}
              alt="Space Apes"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </Box>
          <Slide
            direction="left"
            in={checked}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 1500, exit: 750 }}
          >
            <Box
              sx={{
                position: 'fixed',
                height: { xs: '120px', sm: '160px', md: '220px', lg: '260px' },
                right: { xs: '0px', md: '50px' },
                bottom: { xs: '0px', md: '50px' },
                animation: checked
                  ? `${float} 8s ease-in-out infinite 1.7s`
                  : 'none',
              }}
            >
              <img
                src={Able}
                alt="Able"
                style={{
                  height: 'inherit',
                }}
              />
            </Box>
          </Slide>
          <Slide
            direction="right"
            in={checked}
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 1500, exit: 750 }}
          >
            <Box
              sx={{
                zIndex: 1,
                position: 'fixed',
                height: { xs: '120px', sm: '160px', md: '220px', lg: '260px' },
                left: { xs: '0px', md: '50px' },
                bottom: { xs: '0px', md: '50px' },
                animation: checked
                  ? `${float} 8s ease-in-out infinite 1.7s`
                  : 'none',
              }}
            >
              <img
                src={Sam}
                alt="Sam"
                style={{
                  height: 'inherit',
                }}
              />
            </Box>
          </Slide>
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={async () => {
              if (process.env.REACT_APP_LIVE === 'true') {
                const audio = new Audio('/assets/game_start.mp3');
                await audio.play();
                setChecked(false);
                setFading(false);
                setTimeout(() => {
                  navigate('/start');
                }, 1700);
              }
            }}
          >
            {process.env.REACT_APP_LIVE === 'true' ? 'PLAY' : 'SOON '}
          </CustomButton>
        </Box>
      </Stack>
    </Fade>
  );
}
