// Libraries
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';

// Local
import { toggleDialog, setType } from '../../GameDialogs/Storage/myApesSlice';
import { resetPlanetState } from '../Storage/myPlanetsSlice';
import Footer from '../../GameDialogs/Common/footer';

const Conquer = () => {
  const dispatch = useDispatch();

  return (
    <Footer showCost={true} cost={1000} transparent={true}>
      <Button
        variant="contained"
        onClick={() => dispatch(resetPlanetState())}
        color="error"
        sx={{
          width: { xs: '100%', md: '200px' },
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
        }}
      >
        CANCEL
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          dispatch(toggleDialog());
          dispatch(setType('planets'));
        }}
        color="secondary"
        sx={{
          width: { xs: '100%', md: '200px' },
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
        }}
      >
        CONTINUE
      </Button>
    </Footer>
  );
};

export default Conquer;
