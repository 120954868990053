// Libraries
import React, { useState } from "react";
import { Button, Snackbar, Alert, Typography } from "@mui/material";

// Local
import TokenLogo from "./Token.svg";

export default function AddToken() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const addToken = async () => {
    // Token details
    const tokenAddress = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
    const tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;
    const tokenDecimals = 18;
    const tokenImage =
      "https://spaceapes.game/static/media/token.bd008d0e7a52efbc2493a7f5e8bad3d4.svg";

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        setSeverity("success");
        setMessage("Token added successfully");
        setShow(true);
      } else {
        setSeverity("warning");
        setMessage("Token not added");
        setShow(true);
      }
    } catch (error) {
      setSeverity("error");
      setMessage(error.message);
      setShow(true);
    }
  };

  return (
    <>
      <Button
        size="large"
        sx={{
          color: "white",
          height: "240px",
          width: "344px",
          background: "#111111",
          flexDirection: "column",
          justifyContent: "space-evenly",
          marginBottom: "20px",
          border: "1px solid black",
          "&:hover": {
            background: "#111111",
            cursor: "pointer",
            border: "1px solid grey",
          },
        }}
        onClick={addToken}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
          }}
        >
          ADD $NUTS TO WALLET
        </Typography>
        <img src={TokenLogo} alt="nuts token logo" />
      </Button>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={() => setShow(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShow(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
