import React, { useRef, useState, useEffect } from 'react';

// Explosion component
const Explosion = ({ position, onComplete }) => {
  const meshRef = useRef();
  const [scale, setScale] = useState(0);
  const maxScale = 4; // Maximum scale of the explosion
  const duration = 2; // Duration for scale up and scale down

  useEffect(() => {
    // Scale up the explosion
    let currentScale = 0;
    const scaleUpInterval = setInterval(() => {
      currentScale += maxScale / (duration * 60); // Assuming 60 fps
      if (currentScale > maxScale) {
        clearInterval(scaleUpInterval);
        // Start scale down after reaching max scale
        scaleDown();
      } else {
        setScale(currentScale);
      }
    }, 1000 / 60); // Update every frame

    const scaleDown = () => {
      const scaleDownInterval = setInterval(() => {
        currentScale -= maxScale / (duration * 60);
        if (currentScale <= 0) {
          clearInterval(scaleDownInterval);
          onComplete(); // Notify when animation is complete
        } else {
          setScale(currentScale);
        }
      }, 1000 / 60);
    };

    return () => {
      clearInterval(scaleUpInterval);
      clearInterval(scaleDown);
    };
  }, [duration, maxScale, onComplete]);

  return (
    <mesh ref={meshRef} scale={[scale, scale, scale]} position={position}>
      <sphereGeometry args={[0.2, 16, 16]} />
      <meshBasicMaterial color="red" />
    </mesh>
  );
};

export default Explosion;
