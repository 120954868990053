// Lbraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Grid,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import startFirebase from '../Firebase';

// Local
import aliens from './aliens.png';
import Layout from '../Common/layout';
import {
  setType,
  setStep,
  showDialog,
} from '../GameDialogs/Storage/myApesSlice';
import ApesDialog from '../GameDialogs';

function Option({
  title,
  subtitle,
  info,
  buttonText,
  background,
  type,
  disabled,
}) {
  const dispatch = useDispatch();
  const [apeCount, setApeCount] = useState(0);
  const [fuseCount, setFuseCount] = useState(0);
  const account = useSelector((state) => state.wallet.account);

  const { firestore } = startFirebase();

  useEffect(() => {
    const fetchApeCount = async () => {
      const querySnapshot = await getDocs(
        collection(firestore, `/players/${account}/apes`)
      );
      const dataArray = querySnapshot.docs.map((doc) => doc.data());
      setApeCount(dataArray.length);
    };

    const fetchFuseCount = async () => {
      const querySnapshot = await getDocs(
        collection(firestore, `/players/${account}/apes`)
      );
      const dataArray = querySnapshot.docs.map((doc) => doc.data());

      // Group by levels
      const groups = dataArray.reduce((group, item) => {
        group[item.level] = group[item.level] || [];
        group[item.level].push(item);
        return group;
      }, {});

      // Count the groups of 10 in each level
      const count = Object.values(groups).reduce((total, group) => {
        // The Math.floor function is used here to only count complete groups of 10
        total += Math.floor(group.length / 10);
        return total;
      }, 0);

      setFuseCount(count);
    };

    if (type === 'apes') fetchApeCount();
    if (type === 'fuse') fetchFuseCount();
    // eslint-disable-next-line
  }, [account, firestore]);

  return (
    <Card
      sx={{
        height: 250,
        width: 345,
        background: '#000000',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContent
        sx={{
          height: '100%',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          color: 'black',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          margin: '10px',
        }}
      >
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Container
            sx={{
              textAlign: 'left',
              padding: '0px !important',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textShadow: '2px 2px 2px black',
                color: 'white',
                width: '200px',
              }}
            >
              {title}
            </Typography>
          </Container>
          <Typography
            variant="body1"
            sx={{
              textShadow: '2px 2px 2px black',
              color: 'white',
              textAlign: 'right',
            }}
          >
            {type === 'apes' && apeCount}
            {type === 'fuse' && fuseCount} {info}
          </Typography>
        </Stack>
        <Typography
          variant="caption"
          sx={{
            textShadow: '2px 2px 2px black',
            color: '#FFF500',
            width: '200px',
            textAlign: 'left',
          }}
        >
          {subtitle}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => {
            if (disabled) return;

            dispatch(setType(type));
            dispatch(setStep(1));
            dispatch(showDialog());
          }}
          sx={{
            width: '100%',
          }}
        >
          {disabled ? 'COMING (SOON)' : buttonText}
        </Button>
      </CardActions>
    </Card>
  );
}

const options = [
  {
    title: 'MY SPACE APE ARMY',
    subtitle: 'VIEW ALL YOUR SPACE APES',
    info: 'SAPE',
    buttonText: 'EXPLORE MY SPACE APES',
    background: aliens,
    disabled: false,
    type: 'apes',
  },
  {
    title: 'SUMMON SPACE APES',
    subtitle: 'ADD ADDITIONAL SPACE APES TO YOUR ARMY',
    info: 'STARTING FROM 1000 $NUTS',
    buttonText: 'SUMMON SPACE APES',
    background: aliens,
    disabled: false,
    type: 'summon',
  },
  {
    title: 'FUSE SPACE APES',
    subtitle:
      'INCREASE YOUR WINNING CHANCE! LEVEL UP YOUR SPACE APES BY FUSING THEM',
    info: 'FUSE POSSIBLE',
    buttonText: 'FUSE SPACE APES',
    background: aliens,
    disabled: false,
    type: 'fuse',
  },
];

const BaseCamp = () => (
  <Layout route={'/start'} title="BASECAMP">
    <ApesDialog />
    <Grid
      container
      spacing={2}
      columns={{ xs: 1, sm: 8, md: 12 }}
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100vh',
        overflow: 'auto',
        padding: '20px',
      }}
    >
      {options.map((option, index) => (
        <Grid
          item
          key={index}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            alignItems: 'center',
          }}
        >
          <Option {...option} />
        </Grid>
      ))}
    </Grid>
  </Layout>
);

export default BaseCamp;
