import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

export function Spaceship({ planetPosition, orbitRadius = 50 }) {
  const spaceshipRef = useRef();
  const { nodes, materials } = useGLTF('cargo.glb');

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();

    // Dynamic orbit radius
    const orbitRadiusDynamic = orbitRadius + Math.sin(elapsedTime) * 2; // Varies the radius

    // Adjusted orbit path
    spaceshipRef.current.position.x =
      planetPosition[0] + orbitRadiusDynamic * Math.sin(elapsedTime);
    spaceshipRef.current.position.y =
      planetPosition[1] + Math.sin(elapsedTime * 0.5) * 2; // Adds vertical motion
    spaceshipRef.current.position.z =
      planetPosition[2] + orbitRadiusDynamic * Math.cos(elapsedTime);

    // Look at the planet
    spaceshipRef.current.lookAt(...planetPosition);

    // Rotate the spaceship to face its underside towards the planet
    spaceshipRef.current.rotation.x = Math.PI / 2; // 90 degrees - adjust if necessary
  });

  return (
    <group ref={spaceshipRef} dispose={null}>
      <group position={[0, 0, 0]} scale={[0.2, 0.175, 0.412]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={materials['Metal scratched']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_1.geometry}
          material={materials['SciFi Wall Lighting']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_2.geometry}
          material={materials['Leaded Lights Blue Glass']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_3.geometry}
          material={materials['Bulb Emmision Light']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cargo_hall.geometry}
          material={materials['Metal scratched']}
          position={[-0.004, 0.042, -0.024]}
          scale={[0.895, 0.932, 0.811]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Left_cargo_door.geometry}
          material={materials['Metal scratched']}
          position={[0.876, 0.974, -0.134]}
          rotation={[0, 0, -1.648]}
          scale={[1.598, 0.627, 1]}
        />
        <group
          position={[0.705, 0.976, 1.13]}
          rotation={[0, 0, -0.973]}
          scale={[0.127, 0.105, 0.026]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube008.geometry}
            material={materials['Metal scratched']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube008_1.geometry}
            material={materials['Automotive led lights']}
          />
        </group>
        <group
          position={[1.772, -1.179, 0.088]}
          rotation={[0, 1.571, 0]}
          scale={[0.061, 0.403, 0.207]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder007.geometry}
            material={materials['Metal scratched']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder007_1.geometry}
            material={materials['Automotive led lights']}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Left_weapon_base_shaft.geometry}
          material={materials['Metal scratched']}
          position={[1.061, -0.176, 1.563]}
          rotation={[-Math.PI, 0.085, -Math.PI]}
          scale={[0.183, 0.294, 0.055]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Left_laser_generator.geometry}
            material={materials['Metal scratched']}
            position={[-6.028, -0.01, -0.006]}
            rotation={[-3.075, -0.014, -0.002]}
          >
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Left_laser_gun.geometry}
              material={materials['Brushed metal']}
              position={[-0.01, -0.018, 0.986]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={0.257}
            />
          </mesh>
        </mesh>
        <group
          position={[-0.493, 0.34, -1.01]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.176, 0.052, 0.281]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001.geometry}
            material={materials['Metal scratched']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder001_1.geometry}
            material={materials['Automotive led lights']}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Personal_door.geometry}
          material={materials['Metal scratched']}
          position={[0.698, -0.037, 1.142]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[0.695, 0.043, 0.087]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Rıght_cargo_door.geometry}
          material={materials['Metal scratched']}
          position={[-0.884, 0.974, -0.162]}
          rotation={[0, 0, 1.944]}
          scale={[1.537, 0.684, 1]}
        />
        <group
          position={[-0.711, 0.976, 1.13]}
          rotation={[0, 0, 0.981]}
          scale={[0.128, 0.105, 0.026]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials['Metal scratched']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube007_1.geometry}
            material={materials['Automotive led lights']}
          />
        </group>
        <group
          position={[-2.266, -1.179, 0.088]}
          rotation={[0, 1.571, 0]}
          scale={[0.062, 0.413, 0.212]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder009.geometry}
            material={materials['Metal scratched']}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cylinder009_1.geometry}
            material={nodes.Cylinder009_1.material}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Rıght_weapon_base_shaft.geometry}
          material={materials['Metal scratched']}
          position={[-1.135, -0.176, 1.563]}
          scale={[0.184, 0.294, 0.054]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rıght_laser_genarator.geometry}
            material={materials['Metal scratched']}
            position={[-6.028, -0.01, -0.006]}
          >
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Rıght_laser_gun.geometry}
              material={materials['Brushed metal']}
              position={[-0.01, -0.018, 0.986]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={0.257}
            />
          </mesh>
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload('cargo.glb');

export default Spaceship;
