// Libraries
import { useSelector } from 'react-redux';
import { Stack, Box, Typography } from '@mui/material';

// Local
import { balanceDisplay } from '../../Utils/index';

const Footer = ({ showCost, cost, children, transparent = false }) => {
  const balance = useSelector((state) => state.wallet.balance);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        position: 'fixed',
        bottom: '0px',
        height: showCost ? '125px' : '64px',
        width: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: showCost ? 'flex' : 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: transparent ? 'rgba(17, 17, 17, 0.5)' : '#271A2C',
          padding: '10px',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          TOTAL: {cost} $NUTS
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: '#A1A1A1',
          }}
        >
          Avail. {balanceDisplay(balance)} $NUTS
        </Typography>
      </Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          background: transparent ? 'rgba(17, 17, 17, 0.5)' : '#111111',
          padding: '10px',
          width: '100%',
          height: '64px',
          margin: '0px !important',
          boxSizing: 'border-box',
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default Footer;
