// library
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box, Grid } from '@mui/material';

// Local
import { setStep, reset } from '../Storage/myApesSlice';
import useFetchNewlyMintedApes from '../../APIs/useFetchNewlyMintedApes';
import Footer from '../Common/footer';
import Layout from '../Common/layout';
import Sape from '../Common/sape';

export default function StepThreeSummon() {
  const dispatch = useDispatch();
  const newlyMintedIDs = useSelector(
    (state) => state.myApesVisibility.newlyMintedIDs
  ); // IDs of the apes that were just minted
  const account = useSelector((state) => state.wallet.account);
  const apes = useFetchNewlyMintedApes(account, newlyMintedIDs);

  return (
    <Layout title="SUMMON SAPES">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflowX: 'hidden',
          overflowY: 'auto', // Enable vertical scrolling
          height: `calc(100vh - 128px)`, // Adjust height to viewport minus bottom Stack height
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: { xs: '100%', md: '100%' }, // Responsive width
            margin: 'auto',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#EEEEEE',
              border: '1px solid #00FF00',
              background: '#00990F',
              boxSizing: 'border-box',
              padding: '10px',
              textAlign: 'center',
            }}
          >
            Congrats! You minted {newlyMintedIDs.length} SAPEs
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              justifyContent: 'center', // Center the Grid container
              alignItems: 'center', // Align items vertically in the center
              height: 'inherit',
              overflow: 'inherit',
              color: 'white',
              marginTop: '0px !important',
              width: '100%',
            }}
          >
            {apes.map((option, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // Center the Grid item
                  alignItems: 'center',
                }}
              >
                <Sape {...option} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Footer showCost={false}>
          <Button
            size="large"
            color="warning"
            variant="contained"
            onClick={() => {
              dispatch(setStep(1));
            }}
            sx={{
              border: '1px solid black',
              color: 'white',
              width: { xs: '100%', md: '200px' },
            }}
          >
            MINT MORE
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => {
              dispatch(reset());
            }}
            sx={{
              border: '1px solid black',
              color: 'white',
              width: { xs: '100%', md: '200px' },
            }}
          >
            HOME
          </Button>
        </Footer>
      </Box>
    </Layout>
  );
}
