import React from "react";
import { useThree } from "@react-three/fiber";
import { AxesHelper as ThreeAxesHelper } from "three";
import { AXIS_SIZE } from "../Constants";

// Red Line: Represents the X-axis.
// Green Line: Represents the Y-axis.
// Blue Line: Represents the Z-axis.
// [x, y, z]: What each index in a positions array [red, green, blue] represents.
const AxesHelper = () => {
  const { scene } = useThree();

  React.useEffect(() => {
    const axesHelper = new ThreeAxesHelper(AXIS_SIZE);
    scene.add(axesHelper);

    return () => {
      scene.remove(axesHelper);
    };
  }, [scene]);

  return null;
};

export default AxesHelper;
