// Library
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Local
import { reset } from '../Storage/myApesSlice';

export default function Header({ title }) {
  const account = useSelector((state) => state.wallet.account);
  const dispatch = useDispatch();

  return (
    <AppBar sx={{ position: 'fixed', background: '#111111', color: 'white' }}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div />
        <Stack
          direction={'column'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          {account && (
            <Typography
              variant="caption"
              sx={{
                color: '#9F9F9F',
              }}
            >
              {account.slice(0, 5)}...{account.slice(-5)}
            </Typography>
          )}
        </Stack>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            dispatch(reset());
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
