import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

// Local
import { setMenu, setProfile } from "../../Wallet/Storage/walletSlice";

import ape from "./ape.svg";

const MyProfile = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.wallet.account);

  return (
    <Box
      onClick={() => {
        if (!account) {
          dispatch(setMenu(true));
        } else {
          dispatch(setProfile(true));
        }
      }}
      sx={{
        width: { xs: "100%", md: "60vw" },
        height: "auto",
        background: "#111111",
        padding: "16px",
        borderRadius: "5px 5px 0px 0px",
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        border: "1px solid black",
        "&:hover": {
          cursor: "pointer",
          border: "1px solid grey",
        },
      }}
    >
      <Box>
        <Typography
          gutterBottom
          sx={{
            fontSize: "24px",
            weight: "700",
            lineHeight: "24px",
            color: "white",
          }}
        >
          MY PROFILE
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <AccountBalanceWalletIcon
            sx={{
              color: "#999999",
            }}
          />
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontSize: "16px",
              weight: "300",
              position: "relative",
              top: "2px",
              color: "#EEEEEE",
              paddingLeft: "5px",
            }}
          >
            {account
              ? `${account.slice(0, 5)}...${account.slice(-5)}`
              : "Please connect your wallet"}
          </Typography>
        </Box>
      </Box>
      <img src={ape} alt="ape" style={{ height: "50px", width: "auto" }} />
    </Box>
  );
};

export default MyProfile;
