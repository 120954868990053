import { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import startFirebase from '../Firebase';

const useSubscribeToPlanet = (planetId) => {
  const [planet, setPlanet] = useState(null);

  const { realtimeDB } = startFirebase();

  useEffect(() => {
    const spaceRef = ref(realtimeDB, `/space/galaxy/${planetId}`);

    const unsubscribe = onValue(
      spaceRef,
      (snapshot) => {
        const spaceData = snapshot.val();
        setPlanet(spaceData); // Set the planet data
      },
      (error) => {
        console.error(error);
      }
    );

    // Clean up the subscription on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [planetId, realtimeDB]);

  return { planet };
};

export default useSubscribeToPlanet;
