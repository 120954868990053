// The minimum
export const MINIMUM_FOR_FUSE = 10;
const DECIMAL_PLACES = 2;

// Format balance from wei to ether, used for wallet calculations
export const formatBalance = (rawBalance) => {
  const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(2);
  return balance;
};

// Displays the correct decimal, thousands, millions separators
export const balanceDisplay = (numberString) => {
  const number = parseFloat(numberString);

  return number.toLocaleString(undefined, {
    minimumFractionDigits: DECIMAL_PLACES,
    maximumFractionDigits: DECIMAL_PLACES,
  });
};

// Format chain ID from hex to number
export const formatChainAsNum = (chainIdHex) => {
  const chainIdNum = parseInt(chainIdHex);
  return chainIdNum;
};

// Check if chain ID is accepted
export const chainAccepted = (id) => {
  return ['0x89', '0x13881', 137, 80001].includes(id);
};

// Opens a link in new tab, mostly gitbook
export const openUrlInNewTab = (url) => {
  // Open the URL in a new tab
  window.open(url, '_blank', 'noopener,noreferrer');
};

// Returns boolean for fusible
export const isFusible = (apes) => {
  return apes >= MINIMUM_FOR_FUSE;
};

// Returns the amount of apes that can be fused
export const totalFusible = (apes) => {
  return Math.floor(apes / MINIMUM_FOR_FUSE);
};

export const remainingForFuse = (apes) => {
  return MINIMUM_FOR_FUSE - apes;
};

// Enhanced function to create more varied and vibrant colors
export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Use bitwise operations to create more extreme color variations
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  // Adjust the color components to make them more vibrant
  return `rgb(${r % 255}, ${g % 255}, ${b % 255})`;
};

// Function to check if analytics should be initialized
export const shouldInitializeAnalytics = () => {
  // Check if preferences have been saved
  const wasPreferenceSaved =
    localStorage.getItem('cookieConsentSaved') === 'true';
  const analyticsConsent = localStorage.getItem('analyticsConsent') === 'true';

  return wasPreferenceSaved && analyticsConsent;
};

export const shouldInitializeErrorTracking = () => {
  // Check if preferences have been saved
  const wasPreferenceSaved =
    localStorage.getItem('cookieConsentSaved') === 'true';
  const errorTrackingConsent =
    localStorage.getItem('errorTrackingConsent') === 'true';

  return wasPreferenceSaved && errorTrackingConsent;
};
