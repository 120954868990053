// Libraries
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button, Stack, Divider } from "@mui/material";

// Local
import {
  toggleDialog,
  setVillain,
  setType,
} from "../GameDialogs/Storage/myApesSlice";
import { setMenu } from "../Wallet/Storage/walletSlice";
import { options } from "./options";
import { openUrlInNewTab } from "../Utils";
import GameDialogs from "../GameDialogs";
import Layout from "../Common/layout";
import Villain from "../GameDialogs/Common/Villain";
import villains from "./Assets/villains.png";

const Links = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: { xs: "100%", md: "344px" },
        height: "auto",
        background: "#111111",
        boxSizing: "border-box",
        borderRadius: "5px",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
        }}
      >
        <Stack
          direction={"column"}
          sx={{
            padding: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              weight: "700",
              lineHeight: "16px",
              color: "white",
              textTransform: "uppercase",
            }}
          >
            FIGHT AND WIN <br /> PRIZES EVERY <br /> WEEK
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              lineHeight: "24px",
              color: "#FFFF00",
              textTransform: "uppercase",
            }}
          >
            NEXT REWARDS DISTRIBUTED IN 7H 27MIN 18S
          </Typography>
        </Stack>
        <img
          alt={"villain group"}
          src={villains}
          style={{
            objectFit: "cover",
            height: "auto",
            width: "172px",
          }}
        />
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          margin: "16px",
        }}
      >
        <Button
          size="large"
          variant="contained"
          color="warning"
          state="Enabled"
          sx={{
            width: "100%",
            textTransform: "uppercase",
          }}
          onClick={() => openUrlInNewTab(process.env.REACT_APP_GITBOOK)}
        >
          LEARN MORE
        </Button>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          state="Enabled"
          sx={{
            width: "100%",
            textTransform: "uppercase",
          }}
          onClick={() => {
            navigate("/leaderboard");
          }}
        >
          LEADERBOARD
        </Button>
      </Stack>
    </Box>
  );
};

export default function Villains() {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.wallet.account);

  useEffect(() => {
    if (!account) {
      dispatch(setMenu(true));
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Layout route={"/start"} showLogo={false} title={"BATTLE CRYPTO VILLAINS"}>
      <Stack
        direction={{ xs: "column", sm: "Row" }}
        spacing={2}
        sx={{
          justifyContent: { xs: "flex-start", sm: "center" },
          alignItems: { xs: "center", sm: "flex-start" },
          width: "100%",
          height: "100%",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <Links />
        <Divider
          sx={{
            width: "20px",
            display: { xs: "none", sm: "inline" },
          }}
        />
        <Stack
          direction={"column"}
          spacing={2}
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", md: "344px" },
          }}
        >
          {options.map((option) => (
            <Box
              key={option.id}
              onClick={() => {
                dispatch(toggleDialog());
                dispatch(setType("villains"));
                dispatch(setVillain(option));
              }}
              sx={{
                border: "1px solid black",
                width: "100%",
                "&:hover": {
                  cursor: "pointer",
                  border: "1px solid grey",
                },
              }}
            >
              <Villain option={option} showArrow={true} showLevel={false} />
            </Box>
          ))}
        </Stack>
      </Stack>
      <GameDialogs />
    </Layout>
  );
}
