// Libraries
import React from "react";
import { Typography, Button, Stack } from "@mui/material";

// Local
import bazaar from "./bazaar.png";

const Bazaar = () => (
  <Stack
    direction={"column"}
    sx={{
      width: "100%",
      height: "150px",
      background: "#111111",
      padding: "16px",
      boxSizing: "border-box",
      borderRadius: "5px",
      backgroundImage: `linear-gradient(to right, #111111, transparent), url(${bazaar})`,
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundPosition: "0 0, top right",
      backgroundSize: "100%, auto",
    }}
  >
    <Typography
      gutterBottom
      sx={{
        fontSize: "24px",
        weight: "700",
        lineHeight: "24px",
        color: "white",
      }}
    >
      SPACE BAZAAR
    </Typography>
    <Typography
      gutterBottom
      sx={{
        fontSize: "12px",
        weight: "700",
        lineHeight: "24px",
        color: "#FFFF00",
      }}
    >
      TRADE SAPES AND OTHER GOODIES
    </Typography>
    <Button
      sx={{
        background: "#424242",
        color: "#999999",
        marginTop: "16px",
      }}
      onClick={() => {}} // Same as disabled but with no opacity
    >
      COMING SOON
    </Button>
  </Stack>
);

export default Bazaar;
